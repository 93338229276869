&.modal__invoice {
  width: auto;

  @include media-breakpoint-up("md") {
    min-width: 70rem;
  }

  @include media-breakpoint-down("md") {
    width: 50rem;
  }

  .modal__invoice {
    &-body {
      min-height: 30rem;
    }

    &-btn-change {
      margin: 0;
      height: 3.8rem;
      min-width: 10rem;
      font-weight: 500;
      font-size: 1.25rem !important;
      right: 0;
      color: $primary;
      text-transform: uppercase;
    }

    &-divider {
      &-text {
        margin: 0 auto;
        width: fit-content;
        z-index: 1;
        background: $white;
        padding: 0 0.5rem;
      }

      &-line {
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
      }
    }

    &-basic-data {
      width: 100%;

      @include media-breakpoint-up("sm") {
        width: 55%;
        border-right: 1px solid $gray2;
      }

      @include media-breakpoint-down("xs") {
        border-bottom: 1px solid $gray2;
      }
    }

    &-advanced-data {
      width: 100%;

      .anotation_label {
        position: absolute;
        display: flex;
        height: 1.8rem;
        top: 53%;
        max-width: 41%;

        div {
          font-size: 1.2rem;
          color: #707070;
          max-width: 367px;
          width: 100%;
        }
      }

      @include media-breakpoint-up("sm") {
        width: 45%;
      }
    }

    &-segmented-control {
      width: 100%;
      display: flex;
      background-color: rgba(226, 232, 240, 0.45);
      border-radius: 1rem;
      height: 4rem;

      &-active {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.2rem;
        border: none !important;
        border-radius: 0.8rem;
        background: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
          0px 1px 2px rgba(0, 0, 0, 0.06);
        cursor: pointer;
      }

      &-inactive {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.2rem;
        border: none !important;
        border-radius: 0.8rem;
        cursor: pointer;
      }
    }
  }

  .btn-invoice-method {
    height: 100px;
    width: 150px;
    margin-bottom: 1rem;
  }
}

&.modal__invoice-5uvt-new-client {
  max-width: 70rem;
}