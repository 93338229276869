.react-table {
  &__table {
    margin: 0;
  }

  @include scrollbar(5px, $primary);

  &__th {
    background: $primary;
    color: $white;
    margin-bottom: 0;
    font-size: 1.5rem;

    &.sort-desc {
      box-shadow: inset 0 -3px 0 0 $primary-dark !important;
    }

    &.sort-asc {
      box-shadow: inset 0 3px 0 0 $primary-dark !important;
    }
  }

  &__th,
  &__td {
    flex: 1 1 100%;

    &:not(:last-child) {
      border-right: 1px solid $gray2;
    }
  }

  &__td {
    font-size: 1.5rem;
    min-height: 3.6rem;
  }

  &__body {
    background: $white;

    &-tr {
      border-bottom: 1px solid $gray1;
    }
    &-td {
      border-color: $gray1 !important;
    }

    &.no-data {
      height: 36rem;
    }
  }

  &__loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__no-data {
    top: 3.6rem;
    left: 0;
    right: 0;
    bottom: calc(100% - 36rem);
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__pagination {
    min-height: 4.4rem;

    &-indicator {
      min-height: 3.6rem;
      font-size: 1.4rem;

      input {
        width: 70px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
        color: inherit;
      }
    }
  }
}
