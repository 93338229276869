html {
  overflow: auto;

  @include scrollbar(10px, $gray3);
}

html, body {
  height: 100%;
  font-size: 10px;
  font-family: 'Inter', sans-serif;
}

body { margin: 0; }
select {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyOSAxNCIgd2lkdGg9IjI5Ij48cGF0aCBmaWxsPSIjZDFkMWQxIiBkPSJNOS4zNzcyNyAzLjYyNWw1LjA4MTU0IDYuOTM1MjNMMTkuNTQwMzYgMy42MjUiLz48L3N2Zz4=) center right no-repeat;
}

#root {
  height: 100%;
}

#root, #side-modal-root {
  font-size: 1.6em;
  font-weight: 300;
  letter-spacing: .01em;
  line-height: 1.6;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Regular.ttf) format('opentype'),
  url(../assets/fonts/Inter-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter Semibold';
  src: url(../assets/fonts/Inter-SemiBold.ttf) format('opentype'),
  url(../assets/fonts/Inter-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter Medium';
  src: url(../assets/fonts/Inter-Medium.ttf) format('opentype'),
  url(../assets/fonts/Inter-Medium.woff2) format('woff2');
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}

.new-text-secondary {
  color: $new-text-secondary;
}

.inter-medium {
  font-family: 'Inter Medium' !important;
}

.inter-semibold {
  font-family: 'Inter Semibold' !important;
}

.inter-regular {
  font-family: 'Inter' !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.rounded-xlg {
  border-radius: 1rem !important;
}

.borderless-card {
  border: 0 !important;
}

.min-vh-60 {
  min-height: 60vh;
}

.w-90 {
  width: 90%;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.external-option-activated {
  background-color: $primary3 !important;
  border: 0 !important;
  display: inline-block;
  font-weight: normal !important;
  color: $primary4 !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1.2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-width: 9.5rem;
  justify-content: space-between;
  
  &:hover {
    &:not(:disabled) {
      border: 0 !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 0 !important;
    }
  }
}

.v1 {
  .form-group {
    label {
      font-size: 1.5rem !important;
      font-weight: bold !important;
      color: $new-text-secondary;
    }
  }
}