.skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .skeleton {
        background: #F1F5F9;
        animation: loading 3s alternate infinite;
    }
}

@keyframes loading {
    0% {
        background: #F1F5F9;
    }

    100% {
        background: #E2E8F0;
    }
}