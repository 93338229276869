.filter-ship {
    padding: 0.2rem 0.6rem;
    border-radius: 999px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .6rem;
}

.filter-ship-default {
    border: 1px solid $slate-300;

    &>svg:first-child,
    svg:last-child {
        path {
            fill: $slate-500;
        }
    }
}

.filter-ship-active {
    border: 1px solid #30ABA980;
    background-color: $primary-50;

    p {
        color: $primary-900 !important;
    }

    &>svg:first-child,
    svg:last-child {
        path {
            fill: $primary-900;
        }
    }

    &:hover {
        background-color: $primary-100;
    }
}