.invoice {
  height: 100%;
  background: $empty;
  width: 100%;
  overflow-x: auto;

  &.pending-invoice {
    height: calc(100% - #{$footerHeight});
  }

  .items {
    flex: 1 1 100%;
    position: relative;

    .items-section {
      height: 100%;

      &.category {
        margin-left: 60px;
      }

      .items-grid {
        height: 99.1%;
        max-height: 99.9%;
      }
    }
  }

  .active-invoice {
    background: $white;
    flex: 0 0 40%;

    @include media-breakpoint-up('md') {
      max-width: 550px;
      width: 550px;
    }

    @include media-breakpoint-down('md') {
      flex: 0 0 50%;
    }

    @include media-breakpoint-down('xs') {
      flex: 0 0 100%;
    }

    .invoice-top {
      display: flex;
      border-bottom: 1px solid $gray2;

      &__header {
        height: 40px;

        button {
          height: 40px;
        }

        .title {
          width: 100%;
        }

        &-settings {
          max-height: calc(100% - 40px);
          height: calc(100% - 40px);
          z-index: 1;
          top: 40px;
          border-radius: 0px 0px 5px 5px;

          form {
            max-height: 100%;
          }

          .side-modal__bottom-buttons {
            button {
              padding: 8px;
              border-radius: 0px 0px 5px 5px;
              font-weight: normal;
              font-size: 1.4rem;
              line-height: 21px;
            }
          }
        }

        &-invoice-settings {
          background-color: $white;
          border: 1px solid #cccbcc;
          border-radius: 9px;
          position: absolute;
          z-index: 1;
          right: 5px;
          top: 44px;
          width: 328px;
          padding: 0px 10px;

          h3 {
            color: #00B19D;
            font-weight: 500;
            font-size: 16px;
            line-height: 14px;
          }

          .triangle {
            width: 12px;
            height: 12px;
            background-color: $white;
            border-top: 1px;
            border-left: 1px;
            border-bottom: 0px;
            border-right: 0px;
            border-style: solid;
            border-color: #cccbcc;
            transform: rotate(45deg);
            position: absolute;
            right: 8px;
            top: -6px;
          }
        }
      }

      &__client {
        padding: 1rem .5rem;

        &>svg {
          flex: 1 0 36px;
          width: 36px;
        }

        .btn-add-contact {
          font-size: 1.2rem;
          text-transform: uppercase;
          color: $secondary;
          font-weight: 700;
        }
      }
    }

    .invoice-content {
      height: 100%;
      overflow: auto;

      @include scrollbar(5px, $primary);
    }

    .invoice-bottom {
      background: $gray1;

      &__summary {

        &-row {
          font-size: 1.6rem;
          font-weight: 400;
          color: $title;
          border-bottom: 1px dashed $gray2;
        }
      }

      .pay {
        padding: 1.5rem 1rem;

        .btn-sell-invoice {
          width: 100%;
          color: $white;
          font-size: 2rem;
          font-weight: 600;
          height: inherit;
          display: flex;
          align-items: center;
          padding: .8rem 1.2rem;
          text-transform: uppercase;
        }
      }

      .cancel {
        background: $secondary;
        height: 3.8rem;
        color: $white;

        .btn-cancel-invoice {
          color: $white;
          font-size: 1.5rem;
          height: inherit;
          display: flex;
          align-items: center;
          text-transform: uppercase;
        }
      }
    }

    &__item {
      cursor: pointer;
      margin: 0;
      border-bottom: 1px solid $gray1;
      max-width: 100%;
      animation: fadeIn ease .2s;
      -webkit-animation: fadeIn ease .2s;
      -moz-animation: fadeIn ease .2s;
      -o-animation: fadeIn ease .2s;
      -ms-animation: fadeIn ease .2s;

      &:hover {
        background: $gray1;
      }

      &-data {
        flex: 1 1 50%;
        padding: 0 5px 0 0;
      }

      &-total-price {
        flex: 1 1 25%;
        padding: 0 5px;
      }

      &-name {
        white-space: break-spaces;
      }

      &-name,
      &-total-price {
        color: $title;
        font-weight: 500;
        font-size: 1.5rem;

        @include media-breakpoint-down('md') {
          font-size: 1.3rem;
        }
      }

      &-price,
      &-reference {
        color: $subtitle;
        font-weight: 500;
        font-size: 1.4rem;

        @include media-breakpoint-down('md') {
          font-size: 1.3rem;
        }
      }

      &-actions {
        width: 120px;

        button {
          width: 32px;
          height: 32px;
        }

        @include media-breakpoint-down('md') {
          width: 100px;

          button {
            width: 28px;
            height: 28px;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      &-quantity {
        border: .2rem solid $primary;
        font-weight: 700;
        margin: 0 .5rem;
        width: 38px;
        height: 38px;

        @include media-breakpoint-down('md') {
          width: 32px;
          height: 32px;
        }
      }

      &-delete {
        width: 2.5rem;
        border-right: none;
        padding: 5px 0 !important;

        &:hover {
          background: $secondary !important;

          svg {
            fill: $white !important;
          }
        }
      }
    }
  }

  .new-active-invoice {
    font-family: 'Inter';
    background: $white;
    flex: 0 0 40%;
    border-left: 1px solid #CBD5E1;

    button:focus {
      box-shadow: 0 0 0 0;
      outline: 0;
    }

    .no-item {
      background-color: #F8FAFC;
    }

    @include media-breakpoint-up('md') {
      max-width: 550px;
      width: 550px;
    }

    @include media-breakpoint-down('md') {
      flex: 0 0 50%;
    }

    @include media-breakpoint-down('xs') {
      flex: 0 0 100%;
    }

    .invoice-top {
      display: flex;
      border-bottom: 1px solid #E2E8F0;

      &__header {
        font-family: 'Inter Semibold';
        color: $new-text-primary;
        font-size: 18px;
        border-bottom: 1px solid #E2E8F0;

        &-actions-large {
          display: flex;
          @media (max-width: 768px) {
            display: none;
          }
        }

        &-actions-small {
          display: none;
          padding-left: 16px;
          padding-right: 16px;
          @media (max-width: 768px) {
            display: flex;
          }
        }

        &-buttons {
          border-radius: 50%;

          button:active,
          button:focus {
            border: 0 solid black !important;
          }
        }

        &-buttons:hover {
          background-color: #F1F5F9;

          svg {
            path {
              fill: black;
            }
          }
        }

        &-settings {
          max-height: calc(100% - 40px);
          height: calc(100% - 40px);
          z-index: 1;
          top: 40px;
          border-radius: 0px 0px 5px 5px;

          form {
            max-height: 100%;
          }

          .side-modal__bottom-buttons {
            button {
              padding: 8px;
              border-radius: 0px 0px 5px 5px;
              font-weight: normal;
              font-size: 1.4rem;
              line-height: 21px;
            }
          }
        }

        &-invoice-settings {
          background-color: $white;
          border: 1px solid #cccbcc;
          border-radius: 9px;
          position: absolute;
          z-index: 1;
          right: 5px;
          top: 44px;
          width: 328px;
          padding: 0px 10px;

          &-small-options {
            padding: 0 10px;
            margin: 11px 0;
          }

          h3 {
            color: #00B19D;
            font-weight: 500;
            font-size: 16px;
            line-height: 14px;
          }

          .triangle {
            width: 12px;
            height: 12px;
            background-color: $white;
            border-top: 1px;
            border-left: 1px;
            border-bottom: 0px;
            border-right: 0px;
            border-style: solid;
            border-color: #cccbcc;
            transform: rotate(45deg);
            position: absolute;
            right: 8px;
            top: -6px;
          }
        }
      }

      &__item {
        .btn-add-item {
          font-size: 14px;
          color: #fff;
          border: 1px solid $primary;
          background-color: $primary;
          padding: 0 1rem;
          margin-left: .9rem;
          height: 3.8rem;
          width: 150px;
          text-align: center;
        }
      }

      &__client {
        &>svg {
          flex: 1 0 36px;
          width: 36px;
        }

        .btn-add-contact {
          font-size: 14px;
          border: 1px solid $primary;
          color: $primary;
          padding: 0 1rem;
          // margin-right: 5px;
          margin-left: .9rem;
          height: 3.8rem;

          div {
            @include media-breakpoint-down("xs") {
              display: none;
            }
          }
        }

        &-selector {
          .Select__control {
            border: 0;
          }
        }
      }

      .fast-option-title {
        font-size: 14px;
        margin-bottom: .3rem;
        color: $new-text-primary;

        @include media-breakpoint-down("xs") {
          display: none;
        }
      }
    }

    .invoice-content {
      height: 100%;
      overflow: auto;
      background: #F8FAFC;

      @include scrollbar(5px, $primary);
    }

    &__item {
      margin: 0;
      border-bottom: 1.7px solid #E2E8F0;
      max-width: 100%;
      animation: fadeIn ease .2s;
      -webkit-animation: fadeIn ease .2s;
      -moz-animation: fadeIn ease .2s;
      -o-animation: fadeIn ease .2s;
      -ms-animation: fadeIn ease .2s;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &-image-container {
        border: 2px solid #E2E8F0;
        border-radius: 5px;
        width: 52px !important;
        height: 52px !important;

        img {
          display: block;
          width: 100%;
        }
      }

      &:hover {
        background: #F8FAFC;
      }

      &-warning-container {
        padding-top: 0.25rem;
      }

      &-warning {
        color: #B45309;
        max-height: 32px;
        margin: 0px;
      }

      &-data {
        flex: 1 1 50%;
        padding: 0 5px 0 0;
      }

      &-total-price {
        flex: 1 1 25%;
        padding: 0 5px;
        height: 100%;

        .discount {
          font-family: 'Inter';
          font-size: 12px;
        }
      }

      &-name {
        white-space: break-spaces;

        &.name-large {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      &-name,
      &-total-price {
        color: $title;
        font-family: 'Inter Semibold';
        font-size: 14px;

        // @include media-breakpoint-down('md') {
        //   font-size: 1.3rem;
        // }
      }

      &-price,
      &-reference {
        color: $new-text-tertiary;
        font-weight: 500;
        font-size: 12px;

        // @include media-breakpoint-down('md') {
        //   font-size: 1.2rem;
        // }
      }

      &-actions {
        position: absolute;
        visibility: hidden;
        display: flex;
        justify-content: right;
        top: 13%;
        right: 2px;
        width: 140px !important;
        height: 45px;
        background: #F8FAFC;
        margin-right: 1rem;

        &-button {
          cursor: pointer;
          width: 34px;
          height: 34px;
          border-radius: 50%;

          &.first {
            margin-right: 8px;
          }

          svg {
            margin: auto 0;
          }
        }

        &-button:hover {
          background-color: #F1F5F9;

          svg {
            path {
              fill: black;
            }
          }
        }

        &-button:active {
          background-color: #E2E8F0;
        }

        @include media-breakpoint-down('md') {
          button {
            width: 28px;
            height: 28px;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      &:hover>.new-active-invoice__item-actions {
        visibility: visible;
      }

      &-quantity-container {
        position: absolute;
        height: auto;
        left: 50%;
        top: 1.5rem;
        border-radius: 2rem;
        animation: fadeIn ease .2s;
        -webkit-animation: fadeIn ease .2s;
        -moz-animation: fadeIn ease .2s;
        -o-animation: fadeIn ease .2s;
        -ms-animation: fadeIn ease .2s;

        @media screen and (min-width: 790px) and (max-width: 1140px) {
          left: 44%;
        }

        @media screen and (max-width: 790px) {
          left: 41%;
        }

        svg:hover {
          rect {
            fill: #E2E8F0;
          }
        }

        svg:active {
          rect {
            fill: #CBD5E1;
          }
        }
      }

      &-quantity {
        font-size: 16px;
        color: $new-text-secondary;
        font-weight: 500;
        margin: 0 .5rem;
        width: 38px;

        @include media-breakpoint-down('md') {
          width: 32px;
          height: 32px;
        }
      }

      &-delete {
        width: 2.5rem;
        border-right: none;
        padding: 5px 0 !important;

        &:hover {
          background: $secondary !important;

          svg {
            fill: $white !important;
          }
        }
      }
    }

    .invoice-bottom {
      border-top: 1px solid #E1E7EE;
      background: $white;
      // padding: 1rem;
      animation: fadeIn ease .2s;
      filter: drop-shadow(0px -4px 20px rgba(199, 203, 207, 0.5));
      -webkit-animation: fadeIn ease .2s;
      -moz-animation: fadeIn ease .2s;
      -o-animation: fadeIn ease .2s;
      -ms-animation: fadeIn ease .2s;

      &__summary {
        background: #F8FAFC;
        border-radius: 1rem;
        color: #8996A7;
        margin-bottom: 1.5rem;

        &-row {
          font-size: 1.3rem;
          padding: .7rem;
          color: $title;
          border-bottom: 1px dashed $gray2;

          &:last-child {
            border: 0;
          }

          .desc {
            color: $new-text-tertiary;
          }

          .number {
            font-family: 'Inter Semibold';
            color: $new-text-secondary !important;
          }
        }
      }

      &__actions {
        margin-top: 1.5rem;
      }

      .pay {
        .btn-sell-invoice {
          width: 100%;
          color: $white;
          font-size: 1.8rem;
          height: inherit;
          display: flex;
          align-items: center;
          padding: .8rem 1.5rem;
          border: 0;
        }

        button:disabled {
          background: #C2C9D6;
        }
      }

      .cancel {
        margin-top: 1rem;

        .btn-cancel-invoice {
          padding: 0;
          color: $new-text-tertiary;
          font-size: 14px;
          height: inherit;
          display: flex;
          align-items: center;

          div:first-child {
            font-family: 'Inter Semibold';
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.noitbms-warning {
  border-radius: 5px;
  background-color: rgba(255, 152, 0, 0.2);
  height: 20px;
  display: flex;
  align-items: center;

  svg {
    height: 12px;
    width: 12px;
  }
}