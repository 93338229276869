.electronic-payroll-menu {
    padding: 1rem 2rem;
    border-bottom: 1px solid $slate-200;
    cursor: pointer;

    &:hover {
        background-color: $slate-200-45;
    }
}

.electronic-payroll-status {
    gap: 1rem;

    &-title {
        font-size: 1.4rem;
        margin: 0;
    }

    &-badge {
        font-size: 1.2rem;
        background-color: $slate-200;
        border-radius: 0.6rem;
        padding: 0.4rem 0.8rem;

        span {
            color: $slate-500 !important;
            font-weight: 600;
        }
    }

    &-active {
        background-color: $green-100;

        span {
            color: $green-700 !important;
        }
    }
}

.electronic-payroll-tutorial {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &-video {
        width: 100%;
        height: 34.7rem;
        border-radius: 0.7rem;

        @include media-breakpoint-down('sm') {
            flex-direction: column;
            height: auto;
        }

        iframe {
            width: 100%;
            height: 100%;

            @include media-breakpoint-down('sm') {
                height: auto;
                border-radius: 0.8rem;
                height: 22.8rem;
            }
        }
    }

    &-title {
        color: $slate-900;
        font-size: 1.8rem;
        font-weight: 600;
        margin: 0;

        @include media-breakpoint-down('sm') {
            margin-bottom: 3rem;
        }
    }

    &-actions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        @include media-breakpoint-down('sm') {
            flex-direction: column-reverse;
        }
    }
}

.electronic-pos-modal {
    max-width: 40rem !important;

    &-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 5rem !important;
        align-items: center;
        text-align: center;

        &-icon {
            position: relative;

            &-time {
                position: absolute;
                top: -4px;
                right: 0;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
        }

        &-actions {
            width: 100%;
            display: flex;
            
            button:first-child {
                width: 38%;
            }

            button:last-child {
                width: 60%;
            }
        }
    }
}