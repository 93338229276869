.custom-table-header-actions {
    width: 100%;
    height: 48px;
    padding: 0 8px;
    border-color: $slate-200;
    border-style: solid;
    border-width: 0 0 1px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-cell {
    max-width: 48px;
    padding: 0;
}

.custom-button-action-simple {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 1rem;

    @include media-breakpoint-down('sm') {
        display: none;
    }
}

.custom-button-action {


    @include media-breakpoint-down('sm') {
        &>p {
            display: none;
        }
    }
}

.custom-table-partible {
    display: flex;
    max-width: 100%;

    table {
        width: 40%;
    }

    .custom-table-partible-content {
        width: 60%;
        border-left: 1px solid $slate-300;
        position: relative;

        &-body {
            padding: 16px 16px 32px 16px;
            overflow: hidden;
            overflow-y: auto;
            height: 100%;
            word-wrap: break-word;
            white-space: break-spaces;
        }
    }

    .partible-row-container {
        height: 64px;
        cursor: pointer;

        .partible-cell-container:nth-child(3) {
            align-items: end;
        }

        .partible-cell-container {
            flex-direction: column;
            justify-content: center;
            align-items: start;

            .partible-cell-item {
                display: flex;
                gap: 8px;
            }

            .partible-cell-item:nth-child(2)>p {
                color: $slate-500 !important;
            }
        }
    }

    @include media-breakpoint-down('sm') {
        table {
            width: 100%;
        }

        .custom-table-partible-content {
            display: none;
        }

        .custom-table-partible-content.selected-row {
            display: block;
        }
    }

}

.partible-clickable-overlay {
    position: absolute
}

.custom-table-scrollable {
    @include scrollbar(5px, $gray2);
}

.custom-empty-table {
    width: 100% !important;

    @include media-breakpoint-down('sm') {
        thead {
            tr th {
                min-width: 120px;
            }

            tr th:has(>input) {
                min-width: 48px;
            }
        }
    }
}

.custom-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .8rem 0 1.6rem;
    position: absolute;
    top: 6px;
    width: 100%;
}

.rmdp-top-class {
    padding-top: 30px;
}

.custom-table-container-empty {
    min-height: 44rem;
    white-space: break-spaces;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-table-container {
    border: 1px solid $slate-200;
    background-color: $white;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down('sm') {
        border-radius: 0;
    }

    &-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffffad;
    }

    &-toolbar {
        @extend .custom-table-header-actions;

        @include media-breakpoint-down('sm') {
            &.filters-toolbar {
                @include scrollbar(0, $gray2);
                gap: 30px;
                white-space: nowrap;
            }
        }

        &-filters {
            display: flex;
            align-items: center;
            gap: 8px;

            @include media-breakpoint-down('sm') {
                &.filter-search-bar {
                    width: 100%;

                    &>div:first-child {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-bulk-actions {
        @extend .custom-table-header-actions;
        background-color: $primary-600;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        &-filters {
            display: flex;
            align-items: center;
            gap: 8px;

            button svg path {
                fill: $white;
            }
        }
    }

    .custom-table-container-section {
        width: 100%;
        overflow: auto;
        white-space: nowrap;
        @include scrollbar(5px, $gray2);
    }

    &-body {
        margin: 0;
        border-collapse: collapse !important;
        overflow: hidden;
        width: 100%;
        table-layout: fixed;

        @include media-breakpoint-down('sm') {
            width: auto;

            &.selected-row {
                display: none;
            }
        }

        &.custom-table-expandable,
        &.custom-table-selectable {
            thead tr th:first-child {
                @extend .action-cell;
                display: flex;
                justify-content: center;

                .vertical-separator {
                    display: none;
                }
            }

            tbody {
                .expanded-row {
                    padding: 0 16px !important;
                    height: auto !important;

                    td {
                        width: 100% !important;
                        max-width: 100% !important;
                        margin-left: 24px !important;
                        padding: 16px !important;
                        padding-left: 8px !important;
                    }
                }
            }

            tbody tr td:first-child {
                @extend .action-cell;
                display: flex;
                justify-content: center;
            }

            thead tr th:nth-child(2) {
                padding: 0;
            }

            tbody tr td:nth-child(2) {
                padding: 0;
            }
        }

        &.custom-table-expandable.custom-table-selectable {
            thead tr th:first-child {
                @extend .action-cell;
                max-width: 24px;
                margin-left: 16px;

                .vertical-separator {
                    display: none;
                }
            }

            tbody {
                .expanded-row {
                    padding: 0 16px !important;
                    height: auto !important;

                    td {
                        width: 100% !important;
                        max-width: 100% !important;
                        margin-left: 48px !important;
                        padding: 16px !important;
                    }
                }
            }

            tbody tr td:first-child {
                @extend .action-cell;
                max-width: 24px;
                margin-left: 16px;
            }

            thead tr th:nth-child(2) {
                @extend .action-cell;
                max-width: 24px;
                padding-left: 8px;

                .vertical-separator {
                    display: none;
                }
            }

            tbody tr td:nth-child(2) {
                @extend .action-cell;
                max-width: 24px;
                padding-left: 8px;
            }
        }

        thead {
            border-color: $slate-200;
            border-style: solid;
            border-width: 0 0 1px 0;

            tr {
                height: 40px;
                background-color: $slate-50;

                th:has(> div.actions-header-compacted) {
                    max-width: 48px !important;
                    padding: 0 !important;
                }

                th:has(> div.actions-header-2-items) {
                    max-width: 96px !important;
                    padding: 0 !important;
                }

                th:has(> div.actions-header-3-items) {
                    max-width: 144px !important;
                    padding: 0 !important;
                }

                th {
                    height: 100%;
                    display: flex;
                    flex: 1 1 100%;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 16px;
                    position: relative;

                    &.column-table-sortable {
                        cursor: pointer;
                    }

                    &.column-table-right-alignment {
                        justify-content: flex-end;

                        &>div {
                            flex-direction: row-reverse;
                        }
                    }

                    .custom-table-header-default {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }

                    .vertical-separator {
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }

        tbody {
            display: block;
            height: auto;
            min-height: 40rem;

            tr {
                height: 48px;
                border-color: $slate-200;
                border-style: solid;
                border-width: 0 0 1px 0;
                transition: .4s all;

                td:has(> div.actions-cell-compacted) {
                    max-width: 48px !important;
                    padding: 0 !important;
                    justify-content: center;
                }


                td:has(> div.actions-cell-2-items) {
                    max-width: 96px !important;
                    padding: 0 !important;
                    justify-content: center;
                }

                td:has(> div.actions-cell-3-items) {
                    max-width: 144px !important;
                    padding: 0 !important;
                    justify-content: center;
                }

                td {
                    width: 100%;
                    height: 100%;
                    display: flex !important;
                    flex: 1 1 100%;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 16px;
                    position: relative;

                    &.row-table-right-alignment {
                        justify-content: flex-end;
                    }

                    .actions-cell {
                        display: flex;
                    }

                    .expandable-cell {
                        svg {

                            path {
                                fill: $slate-500;
                            }
                        }
                    }
                }

                &.selected-row {
                    background-color: $primary-50;

                    &:hover {
                        background-color: $primary-50;
                    }
                }

                &:hover {
                    background-color: $slate-200-45;
                }
            }
        }
    }

    &-pagination {
        width: 100%;
        display: flex;
        height: 48px;
        border: 1px 0 0 0;
        padding: 0 8px;
        justify-content: space-between;
        align-items: center;
        border-color: $slate-200;
        border-style: solid;
        border-width: 1px 0 0 0;

        &-items {
            display: flex;
            gap: 16px;
            align-items: center;
        }

        &-page-info {
            display: flex;
            gap: 8px;
            align-items: center;
        }

        .pagination-items-per-page {
            margin: 0;
            position: relative;
            width: 66px;
            display: flex;
            align-items: center;

            &>div {
                position: absolute;
                width: 100%;
                height: 32px;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2.4rem;

        &-title {
            text-align: center;
        }
    }
}