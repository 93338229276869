&.modal__new-close-shift {
  font-family: 'Inter';
  max-height: 90vh;
  max-width: 48rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .modal__close-shift {
    &-header {
      font-family: 'Inter Semibold';
      border-top-left-radius: 0.7rem;
      border-top-right-radius: 0.7rem;
      position: sticky;
      top: 0;
      background-color: white;
      font-size: 16px;
      padding: 12px 16px;
      border-bottom: 1px solid rgba(203, 213, 225, 1);
      z-index: 100;
    }

    &-content {
      font-size: 14px;
      position: relative;

      .close-shift {
        &-date-container {
          border-bottom: 1px solid #cbd5e1;
          font-size: 14px;
          background-color: #F8FAFC;
          padding: 12px 16px;

          &__date {
            padding-left: 8px;
            color: #64748B;
          }
        }

        &-sales-container {
          padding: 12px 24px;
          border-bottom: 1px solid #E2E8F0;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__sales-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 0;
            width: 100%;
            margin: 0px;
            border-bottom: 1px solid #E2E8F0;
          }

          &__sales-info:last-child {
            font-family: 'Inter Semibold';
            border-bottom: none;
            // border-top: 1px solid #64748B;
          }
        }

        &-info-container {
          padding: 12px 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          &>div {
            padding: 8px 0;
            line-height: 20px;
            border-bottom: 1px solid #E2E8F0;

            &:last-child {
              border-top: 1px solid #64748B;
              margin-top: -1px;
              border-bottom: none;
            }
          }
        }

        &-cash-info-container {
          display: flex;
          justify-content: space-between;
          margin: 0 16px 12px 16px;
          padding: 8px 12px;
          font-size: 16px;
          border: 1px solid #E2E8F0;
          border-radius: 8px;
          background: #F8FAFC;
        }
      }

      .cash-movement-btn {
        font-size: 14px !important;
        padding-bottom: 12px;
        border-bottom: 1px solid #E2E8F0;

        button {
          padding: 8px;
        }
      }

      .close-shift-body {
        font-size: 14px !important;
        padding: 12px 16px;

        p {
          margin-bottom: 3px;
        }

        .input-number-wrapper {
          width: 100%;
          border-radius: 5px;
          border: 1px solid #CCCCCC;
        }

        .description {
          font-size: 12px;
          margin-bottom: 2px;
          color: #64748B;
        }
      }
    }
  }

  .close-shift-bottom {
    font-family: 'Inter';
    font-size: 12px;
    border-top: 1px solid rgba(203, 213, 225, 1);
    background-color: #fff;
    position: sticky;
    bottom: -1px;
    z-index: 101;

    .required-message {
      padding: 23px 0 22px 16px;
      min-width: 148px;

      +div {
        width: 80%;
        padding-right: 18px;
      }
    }

    .btn {
      padding: 0;
      max-height: 36px;
    }

    .btn-cancel {
      color: #47536B !important;
      font-weight: 100;
      min-width: 85px;
      max-width: 85px;
    }

    .btn-submit {
      margin-left: 16px;
      min-width: 80px;
      max-width: 80px;
    }
  }
}

// &.modal__new-close-shift::-webkit-scrollbar {
//   display: none;
// }