.item-summary {
  background: $gray1;

  &__row {
    font-size: 1.6rem;
    font-weight: 400;
    color: $title;

    &:last-child {
      font-size: 1.6rem;
      font-weight: 600;
      color: $title;
    }

    &:not(:last-child) {
      border-bottom: 1px dashed $gray2;
    }
  }
}

.new-active-invoice__item-warning {
  max-height: 12px;
}

.new-edit-item-form {
  font-family: 'Inter';
  .label-wrapper {
    color: #0F172A !important;
    label {
      font-size: 14px;
    }
  }
  .tax-selector {
    span {
      font-family: 'Inter Semibold';
    }
  }
}

.item-info-container {
  border-bottom: 2px solid #E2E8F0;
}

.item-info {
  font-family: 'Inter';
  display: flex;
  font-size: 14px;

  &__image {
    width: 68px;
    height: 68px;
    border: 2px solid #E2E8F0;
    border-radius: 5px;

    img,
    svg {
      display: block;
      width: 100% !important;
    }
  }

  &__detail {
    p {
      margin: 0;
    }

    &-product-type {
      color: #0F172A;
    }

    &-product-name {
      font-family: 'Inter Semibold';
      color: #334155;
    }

    &-product-reference {
      color: #64748B;
    }
  }

  &__delete {
    margin-left: auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 34px;
    height: 34px;
    border-radius: 50%;

    svg {
      margin: auto 0 !important;
    }
  }

  &__delete:hover {
    background-color: #F1F5F9;

    svg {
      path {
        fill: black;
      }
    }
  }

  &__delete:active {
    background-color: #E2E8F0;
  }

  div {
    overflow-y: hidden !important;
  }
}

.new-item-summary {
  background: #F8FAFC;

  &__row {
    font-size: 1.6rem;
    font-weight: 400;

    &-concept {
      font-size: 12px;
      color: #64748B;
    }

    &-value {
      font-size: 14px;
      color: #334155;
    }

    &:last-child {
      font-size: 16px;
      font-family: 'Inter Semibold';
      color: #334155;
    }

    &:not(:last-child) {
      border-bottom: 1px dashed $gray2;
    }
  }
}