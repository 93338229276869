// Colors
@import "colors";

// Variables
@import "variables";

// Mixins
@import "mixins";

// Initial
@import "initial";

@import "~bootstrap/scss/bootstrap.scss";

// Components
@import "components/app";
@import "components/notifications";
@import "components/header";
@import "components/main";
@import "components/sidemodals";
@import "components/forms";
@import "components/settings";
@import "components/membership";
@import "components/modals";
@import "components/toast";
@import "components/hubspot";
@import "components/print";
@import "components/statistics";
@import "components/onboarding";
@import "components/inventory";
@import "components/items";
@import "components/action-buttons";
@import "components/details";
@import "components/inventory-adjustments";
@import "components/popover";
@import "components/network-status";
@import "components/electronic-payroll-status";
@import "components/react-select";
@import "components/buttons.scss";
@import "components/typography";
@import "components/tag";
@import "components/navigation-menu";
@import "components/custom-table";
@import "components/date-picker";
@import "components/filter-ship";
@import "components/global-invoices/global-invoices";
@import "components/skeleton";

// External libraries
@import "components/react-table";
@import "components/react-table-gray";
@import "components/react-table-light";
@import "components/react-table-simplified";
@import "components/react-datepicker";

@include media-breakpoint-down("xs") {

  html,
  body {
    font-size: 9px;
  }
}