.form__invoice {
  &-divider {
    &-text {
      margin: 0 auto;
      width: fit-content;
      z-index: 1;
      background: $white;
      padding: 0 .5rem;
    }

    &-line {
      width: 100%;
      display: block;
      position: absolute;
      top: 50%;
    }
  }

  &-cash-options {
    justify-content: space-between;
    
    button {
      min-width: unset !important;
      margin-bottom: 1rem;
      width: 100%;
      flex: 0 1 100%;

      @include media-breakpoint-up('sm') {
        width: 30%;
        flex: 0 1 30%;
      }
    }
  }

  &-qr-zone {
    width: 200px;
    height: 200px;
    border: 1.9px solid #00b19d;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
}