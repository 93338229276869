&.modal__change-ordinary-numeration {
  width: auto;
  border-radius: 16px !important;

  @include media-breakpoint-up('md') {
    width: 41.4rem;
  }

  @include media-breakpoint-down('md') {
    width: 40rem;
  }
  .modal__change-ordinary-numeration {
    &-currency-icon {
      top: 0;
      right: 0;
    }
  }

  .btn-submit {
    border-radius: 10px !important;
    background: #30aba9 !important;
    height: 3em;
    padding-right: 1.2em;
    padding-left: 1.2em;
  }

  .btn-cancel {
    border-radius: 10px !important;
    height: 3em;
    padding-right: 1.2em;
    padding-left: 1.2em;
  }

  a {
    color: #30aba9;
    font-weight: 600;
  }
}

.modal__body {
  display: flex;
  flex-direction: column;
  padding: 5em 1.5em 1.5em 1.5em;
}
