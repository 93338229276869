.reset-typography {
    margin: 0;
    padding: 0;
    border: 0;
}

.font-regular {
    font-family: 'Inter';
    font-weight: 400;
}

.font-medium {
    font-family: 'Inter Medium';
    font-weight: 500;
}

.font-semibold {
    font-family: 'Inter Semibold';
    font-weight: 600;
}

.text-7xl {
    font-size: 64px;
    line-height: 72px;
}

.text-6xl {
    font-size: 56px;
    line-height: 64px;
}

.text-5xl {
    font-size: 48px;
    line-height: 56px;
}

.text-4xl {
    font-size: 40px;
    line-height: 48px;
}

.text-3xl {
    font-size: 32px;
    line-height: 40px;
}

.text-2xl {
    font-size: 24px;
    line-height: 32px;
}

.text-xl {
    font-size: 20px;
    line-height: 28px;
}

.text-lg {
    font-size: 18px;
    line-height: 30px;
}

.text-base {
    font-size: 16px;
    line-height: 26px;
}

.text-sm {
    font-size: 14px;
    line-height: 24px;
}

.text-xs {
    font-size: 12px;
    line-height: 16px
}

.display-1 {
    @extend .text-7xl;
    @extend .font-medium;
}

.display-2 {
    @extend .text-6xl;
    @extend .font-medium;
}

.display-3 {
    @extend .text-5xl;
    @extend .font-medium;
}

.heading-1 {
    @extend .text-4xl;
    @extend .font-semibold;
}

.heading-2 {
    @extend .text-3xl;
    @extend .font-semibold;
}

.heading-3 {
    @extend .text-2xl;
    @extend .font-semibold;
}

.heading-4 {
    @extend .text-xl;
    @extend .font-semibold;
}

.subheading-1 {
    @extend .text-2xl;
    @extend .font-regular;
}

.subheading-2 {
    @extend .text-xl;
    @extend .font-regular;
}

.body-1-regular {
    @extend .text-lg;
    @extend .font-regular;
}

.body-1-bold {
    @extend .text-lg;
    @extend .font-semibold;
}

.body-1-italic {
    @extend .text-lg;
    @extend .font-regular;
    font-style: italic;
}

.body-1-underline {
    @extend .text-lg;
    @extend .font-regular;
    text-decoration: underline;
}

.body-2-regular {
    @extend .text-base;
    @extend .font-regular;
}

.body-2-bold {
    @extend .text-base;
    @extend .font-semibold;
}

.body-2-italic {
    @extend .text-base;
    @extend .font-regular;
    font-style: italic;
}

.body-2-underline {
    @extend .text-base;
    @extend .font-regular;
    text-decoration: underline;
}

.body-3-regular {
    @extend .text-sm;
    @extend .font-regular;
}

.body-3-bold {
    @extend .text-sm;
    @extend .font-semibold;
}

.body-3-italic {
    @extend .text-sm;
    @extend .font-regular;
    font-style: italic;
}

.body-3-underline {
    @extend .text-sm;
    @extend .font-regular;
    text-decoration: underline;
}

.caption-regular {
    @extend .text-xs;
    @extend .font-regular;
}

.caption-bold {
    @extend .text-xs;
    @extend .font-semibold;
}

.label-1 {
    @extend .text-base;
    @extend .font-medium;
}

.label-2 {
    @extend .text-sm;
    @extend .font-medium;
}

.label-3 {
    @extend .text-xs;
    @extend .font-medium;
}

.color-primary {
    color: $slate-900;
}

.color-secondary {
    color: $slate-700;
}

.color-green {
    color: #00B19D;
}

.color-tertiary {
    color: $slate-500;
}

.color-placeholder {
    color: $slate-400;
}

.color-disabled {
    color: $slate-300;
}

.color-inverse {
    color: $white;
}

.color-danger {
    color: $danger;
}

.color-danger2 {
    color: $danger2;
}

.color-new-text-primary {
    color: $new-text-primary;
}

.color-new-text-tertiary {
    color: $new-text-tertiary;
}