@mixin scrollbar($width, $color) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    outline: none;
    border-radius: 5px;
  }
}

@mixin button-sm($background-enabled,
  $background-hover,
  $background-pressed,
  $font-color,
  $border-color) {
  // Button Style
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0.6rem 0.8rem;
  gap: 0.4rem;
  min-height: 3.2rem;
  border: 1px solid $border-color !important;
  border-radius: 0.8rem;
  background: $background-enabled !important;
  position: relative;

  // Button Font Style

  font-size: 1.4rem;
  line-height: 2rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: $font-color;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    &:not(:disabled) {
      border: 1px solid $border-color !important;
      background: $background-hover !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 1px solid $border-color !important;
      background: $background-pressed !important;
    }
  }

  &:disabled {
    opacity: 0.3;
    // border: 1px solid red !important;
  }

  &::first-letter {
    text-transform: uppercase !important;
  }

  @media (max-width: 576px) {
    height: 4rem;
  }
}

@mixin button-icon($background-enabled,
  $background-hover,
  $background-pressed,
  $font-color,
  $border-color) {
  @include button-sm($background-enabled,
    $background-hover,
    $background-pressed,
    $font-color,
    $border-color);

  width: 3.2rem;
  height: 3.2rem;
  padding: 0;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }

  @media (max-width: 576px) {
    height: 3.2rem;
  }
}

@mixin button-md($background-enabled,
  $background-hover,
  $background-pressed,
  $font-color,
  $border-color) {
  // Button Style
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 2rem;
  gap: 0.4rem;
  height: 4rem;
  border: 1px solid $border-color !important;
  border-radius: 1rem;
  background: $background-enabled !important;
  position: relative;

  // Button Font Style

  font-size: 1.6rem;
  line-height: 2.4rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: $font-color;

  &:hover {
    &:not(:disabled) {
      border: 1px solid $border-color !important;
      background: $background-hover !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 1px solid $border-color !important;
      background: $background-pressed !important;
    }
  }

  &:disabled {
    opacity: 0.3;
    // border: 1px solid red !important;
  }

  &::first-letter {
    text-transform: uppercase !important;
  }

  @media (max-width: 576px) {
    height: 4rem;
  }
}

@mixin button-lg($background-enabled,
  $background-hover,
  $background-pressed,
  $font-color,
  $border-color) {
  // Button Style
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 2.4rem;
  gap: 0.4rem;
  min-height: 4.8rem;
  border: 1px solid $border-color !important;
  border-radius: 1.2rem;
  background: $background-enabled !important;
  position: relative;

  // Button Font Style

  font-size: 1.6rem;
  line-height: 2.4rem;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: $font-color;

  &:hover {
    &:not(:disabled) {
      border: 1px solid $border-color !important;
      background: $background-hover !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 1px solid $border-color !important;
      background: $background-pressed !important;
    }
  }

  &:disabled {
    opacity: 0.3;
    // border: 1px solid red !important;
  }

  &::first-letter {
    text-transform: uppercase !important;
  }

  @media (max-width: 576px) {
    height: 4rem;
  }
}