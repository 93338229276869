.detail-header {
  background: $white;
  border-radius: 8px 8px 0 0;

  .actions {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3.6rem !important;
      margin-bottom: .5rem;
      margin-right: 1rem;
    }
    @include media-breakpoint-down('xs') {
      button {
        min-width: unset !important;
      }
    }
  }

  .edit-field {
    max-width: 25rem;
    margin-bottom: 0;
    height: 2.4rem;
  }

  p {
    margin-bottom: 0;
  }
}

.detail-body {
  background: $white;
  border-radius: 0 0 8px 8px;

  .edit-field {
    width: 100%;
    max-width: 35rem;
    margin-bottom: 0;
    height: 2.4rem;

    &.currency {
      max-width: 18rem;

      padding: 0 1rem 0 0;
      margin-bottom: .25rem;
    }

    input[type=number], input[type=text] {
      height: 2.4rem !important;
      font-size: 12px !important;
    }
  }
}

.detail-buttons {
  .required-message {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $gray3;
  }

  button:not(:last-child) {
    margin-right: 1rem;
  }
}

.detail-info-table {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  @include scrollbar(5px, $gray2);

  td {
    padding: .5rem 1rem;
    font-size: 14px;
    line-height: 21px;
    color: $gray6;
    border-bottom: 0.1rem solid #ededed;
    
    &:first-child {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      background: $grayblue;
      color: $grayblue7;
      width: 15rem;
      border-bottom: 0.1rem solid $grayblue;
    }

    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  & tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }

  & tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
    border-bottom: none;
  }

  & tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }

  & tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

}

.detail-items-table-wrapper {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  @include scrollbar(5px, $gray2);

  .detail-items-table {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    color: $gray6;

    th {
      padding: .5rem;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      background: $grayblue;
      color: $grayblue7;
      text-transform: uppercase;
      border-bottom: 0.1rem solid #ededed;
      &::first-letter {
        text-transform: uppercase !important;
      }
      
      &:first-child {
        border-top-left-radius: 8px;
      }
      
      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    td {
      padding: .5rem;
      font-size: 1.4rem;
      border-bottom: 0.1rem solid #ededed;
    }

    .edit-field {
      max-width: none;

      input[type=number], input[type=text] {
        height: 2.4rem !important;
        font-size: 12px !important;
      }
    }
    
    .items-field {
      width: 30%;
      max-width: 100%;
      min-width: 20rem;
    }

    .reference-field {
      width: 15%;
      max-width: 15rem;
      min-width: 10rem;
    }

    .price-field {
      width: 15%;
      max-width: 15rem;
      min-width: 15rem;
    }
    
    .discount-field {
      width: 5%;
      max-width: 5rem;
      min-width: 5rem;
    }
    
    .taxes-field {
      width: 12%;
      max-width: 12rem;
      min-width: 9.5rem;
    }
    
    .quantity-field {
      width: 5%;
      max-width: 5rem;
      min-width: 5rem;
    }

    .total-field {
      width: 12%;
      max-width: 12rem;
      min-width: 9.5rem;
    }
    
    .delete-field {
      width: 18px;
      max-width: 20px;
      min-width: 18px;
      padding: 0;

      button {
        padding: 0;
        line-height: 0;

        svg {
          width: 18px;
          height: 18px;
        }
      }

    }
  }

  &.btn {
    color: $primary;
    background: $white;
    font-size: 12px;
    line-height: 18px;
    height: 2.4rem;
    padding: 0 1rem;
  }
}

.detail-payments-table {
  max-width: 100%;
  width: Min(25rem, 100%);

  &.edit-mode {
    td:first-child {
      min-width: 15rem;
    }

    td:last-child {
      min-width: 15rem;
    }

    .edit-field {
      max-width: none;

      input[type=number], input[type=text] {
        height: 2.4rem !important;
        font-size: 12px !important;
        text-align: right;
      }

    }

    .field-warning {
      input {
        border-color: $warning !important;
      }
    }
  }

  font-size: 14px;
  line-height: 21px;
  color: $gray6;

  th {
    padding: .5rem 1rem;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;
    background: $grayblue;
    color: $grayblue7;
    text-transform: uppercase;
    border-bottom: 0.1rem solid #ededed;
    &::first-letter {
      text-transform: uppercase !important;
    }

    &:first-child {
      border-top-left-radius: 8px;
    }
    
    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  td {
    padding: .5rem 1rem;
    font-size: 1.4rem;
    border-bottom: 0.1rem solid #ededed;

    &:last-child {
      text-align: right;
    }
  }
}

.detail-payments-table-wrapper {
  overflow: hidden;
  overflow-x: auto;

  @include scrollbar(5px, $gray2);
  width: Min(40rem, 100%);
}

.detail-summary-table {
  max-width: 100%;
  width: Min(30rem, 100%);
  margin-top: 3rem;

  tr {
    font-weight: 500;
    font-size: 16.319px;
    line-height: 19px;
    color: $gray6;
    &:last-child {
      border-top: 0.1rem solid $gray2;
      font-size: 24px;
      line-height: 28px;
      color: $title;
    }
  }
  
  td {
    padding: .5rem 1rem;
    &:first-child {
      font-weight: 500;
      text-align: right;
    }
    &:last-child {
      text-align: right;
    }

  }
}

.badge {
  margin: 0 .5rem;
  &-open {
    color: $white;
    background: $danger;
    &-panama {
      color: $white;
      background: $danger;
    }
  }
  &-closed {
    color: $white;
    background: $primary;

    &-panama {
      color: $white;
      background: $primary;
    }
  }
  &-void {
    color: $white;
    background: $secondary;

    &-panama {
      color: $white;
      background: orange;
    }
  }
   &-process {
    &-panama {
      color: $white;
      background: #4A90E2;
    }
  }
}