.menu {
  &__header {
    max-height: 14rem;
    box-shadow: 0px 0.5px 4px $gray3;

    &-info {
      padding: 2rem 1.5rem;

      .shift-information {
        &-title {
          color: $slate-700;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 2.8rem;
        }

        &-subtitle {
          font-size: 1.4rem;
          color: $slate-500;
          margin-bottom: 0;
          line-height: 1.6rem;
        }
      }
    }
  }

  &__submenu {
    &_header {
      color: #707070;


      &.active {
        border-left: 0.4rem solid $primary !important;
        padding-left: 1.1rem;
      }

      &.open {
        color: $white;
        background: $primary;
      }

      .icon-primary {
        &:hover {
          fill: #fff !important;
        }
      }

      &:hover:not(.disabled) {
        color: $white;

        .icon-primary {
          fill: #fff !important;
        }
      }
    }

    &_options {
      background: $gray1;
    }

    &_option {
      padding-left: 2.8rem !important;
    }

    &.disabled * {
      color: $gray2;
    }
  }

  &__content {
    flex: 1 1 100%;
    overflow: hidden;
    overflow-y: auto;

    @include scrollbar(5px, $primary);

    ul {
      list-style: none;
      padding: 0;
    }

    &_option {
      padding: 1rem 1.5rem;
      height: 4.5rem;
      font-size: 1.5rem;
      font-weight: 500;

      * {
        color: $subtitle;
        text-decoration: none;

        a {
          cursor: default;
        }

        &.active {
          color: $primary;
        }
      }

      &.disabled * {
        color: $gray2;
      }

      &:hover:not(.disabled) {
        background: $primary;
        cursor: pointer;

        * {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  &__footer {
    flex: 2;
  }
}

.custom-menu-large {
  width: 32rem;
}