#husbpot-chat {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;

  .btn-chat-container {
    box-shadow: rgba(0, 0, 0, .1) 0px 1px 6px, rgba(0, 0, 0, .2) 0px 2px 24px;
    transition: box-shadow 150ms ease-in-out 0s;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    cursor: pointer;
    position: absolute;
    left: 16px;
    bottom: 16px;

    &:hover {
      box-shadow: rgba(0, 0, 0, .2) 0px 2px 10px, rgba(0, 0, 0, .3) 0px 4px 28px;
    }
  }
}

#hubspot-messages-iframe-container, #husbpot-chat {
  z-index: 1 !important;

  &.home-page {

    @include media-breakpoint-up('sm') {

      &.categories {
        margin-left: 5rem !important;
      }
    }
    
    &.pending-invoices {
      margin-bottom: 3.5rem !important;
    }
  }
}