.inventory {
  overflow: auto;
  @include scrollbar(5px, $primary);
}

.inventory-menu {
  &__option {
    flex: 0 0 22rem;
    min-height: 27rem;
    max-height: 27rem;
    background: $white;
    border-radius: 6px;
    border-bottom: 3px $primary solid;
    margin: 0 1rem 2rem;
    padding: 1rem;

    &-header {
      font-weight: 500;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $gray1;
    }

    &-description {
      font-size: 1.2rem;
      margin-bottom: 1rem;
      max-height: 8rem;
      min-height: 8rem;
    }

    &-options {
      font-size: 1.4rem;

      a:not(:last-child) {
        padding-bottom: 0.5rem;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
}

.table-actions {
  > * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  button {
    transition: border 0.2s ease;
    padding-left: 1px;
    padding-right: 1px;
    border: 1px $gray2 solid;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover:not(:disabled) {
      border-color: $primary;

      &.button-remove {
        border-color: $danger;
      }
    }

    &:disabled {
      svg {
        fill: $gray3 !important;
      }
    }
  }
}

.table-gray-actions {
  > * {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  button {
    transition: border 0.2s ease;
    padding-left: 1px;
    padding-right: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
    svg {
      width: 22px;
      height: 22px;
    }

    &:disabled {
      svg {
        fill: $gray3 !important;
      }
    }
  }
}

.table-light-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;

  width: 64px;
  height: 24px;
}
