.react-table-gray {
  th,
  td {
    border: 0px !important;
  }
  &__table {
    margin: 0;
    border-collapse: collapse !important;
    border-radius: 10px 10px 0px 0px !important;
    overflow: hidden;
  }

  @include scrollbar(5px, $primary);

  &__head {
    border-radius: 10px 10px 0px 0px !important;
    tr {
      border-radius: 10px 10px 0px 0px !important;
      border: 1px solid #cccbcc;
    }
    th:first-child {
      border-radius: 10px 0px 0px 0px !important;
    }
    th:last-child {
      border-radius: 0px 10px 0px 0px !important;
    }
  }

  &__th {
    background: #f9fafc;
    color: #949494;
    margin-bottom: 0;
    font-size: 1.5rem;
    padding: 12px 20px !important;
    &.sort-desc {
      box-shadow: inset 0 -3px 0 0 $primary-dark !important;
    }

    &.sort-asc {
      box-shadow: inset 0 3px 0 0 $primary-dark !important;
    }
  }

  &__th,
  &__td {
    flex: 1 1 100%;

    &:not(:last-child) {
      border-right: 0px solid $gray2;
    }
    
    overflow:hidden;
    text-overflow: ellipsis;
    
  }

  &__td {
    font-size: 1.5rem;
    min-height: 3.6rem;
    padding: 10px 20px !important;
  }

  &__body {
    background: $white;

    &-tr {
      border-bottom: 0px solid $gray1;
      height: 41px !important;
      &:nth-child(even) {
        background-color: $empty;
      }
    }
    &-td {
      border-color: $gray1 !important;
      display: flex !important;
      align-items: center;
    }

    &.no-data {
      height: 36rem;
    }
  }

  &__loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__connection-issue {
    @extend .react-table-gray__loading;
    min-height: 10rem;
    gap: 2.4rem;

    .un-allow-icon {

      width: 4.8rem;
      height: 4.8rem;

      path {
        fill: #64748B
      }
     
    }

   section {
    text-align: center;

    h2 {
      font-size: 2.4rem;
    }

    p {
      font-size: 1.4rem;
    }
    button.button-menu-action {
      font-family: 'Inter Medium';
      margin-top: 24px;
      width: 204px;
      border-radius: 10px;
      padding: 0px 8px;
      font-size: 16px;

      @include media-breakpoint-down("sm") {
          width: 100%;
      }
    }
   }
  }

  &__no-data {
    top: 30%;
    left: 0;
    right: 0;
    bottom: calc(100% - 36rem);
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
  }

  &__pagination {
    min-height: 4.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: center;
      height: 100% !important;

      .carrent {
        &__simple {
          background: $empty;
          width: 22px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
        }
        &__doble {
          background: $empty;
          width: 44px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      input {
        width: 5rem;
        border: 1px solid #cccccc;
        border-radius: 4px;
        background: #fff;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: normal;
        outline: none;
        color: $subtitle;
      }

      .description {
        color: #00535e;
        font-weight: 500;
        font-size: 14px;

        @include media-breakpoint-down("md") {
          display: none;
        }

        @include media-breakpoint-down("sm") {
          display: none;
        }

        @include media-breakpoint-down("xs") {
          display: none;
        }
      }

      .results {
        font-weight: 300;
        color: #00535e;
        font-size: 14px;
        border-left: 1px solid #cccccc;
        padding: 0px 5px;

        @include media-breakpoint-down("sm") {
          width: 10rem;
          font-size: 12px;
          display: inline;
        }

        @include media-breakpoint-down("xs") {
          display: inline;
        }
      }

      .reload {
        background: #f7f7f7;
        width: 22px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        margin: 0px 8px;
      }

      .options {
        width: 6.5rem;
      }


      @include media-breakpoint-down("xs") {
        justify-content:space-around;
        align-items: center;
        padding: 1rem;
      }

    }
  }
}

.styled-hover:hover::before {
  content: attr(hovercontent);
  position: absolute;
  padding: 10px;
  border: 1px solid $gray1;
  background: $white;
  font-size: 12px;
  color: $blue-dark;
  text-align: center;
  transform: translateY(-40px);
  border-radius: 10px;
  box-shadow: 5px 5px 5px $gray1;
  z-index: 10;
  transition: 1s;
}
