&.modal__refunds {
  @include media-breakpoint-up('md') {
    max-width: 75rem;
    width: 75rem;
  }

  @include media-breakpoint-down('sm') {
    width: 50rem;
  }

  .modal__refunds {

    &-body {
      height: 50rem;
    }

    &-btn-change {
      margin: 0;
      height: 3.8rem;
      min-width: 10rem;
      font-weight: 500;
      font-size: 1.25rem !important;
      right: 0;
      color: $primary;
      text-transform: uppercase;
    }

    &-divider {
      &-text {
        margin: 0 auto;
        width: fit-content;
        z-index: 1;
        background: $white;
        padding: 0 .5rem;
      }

      &-line {
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
      }
    }
  }

  .active-refund {
    background: $white;
    height: 100%;

    .refund-top {
      display: flex;
      border-bottom: 1px solid $gray2;

      &__header {
        height: 40px;

        button {
          height: 40px;
        }

        .title {
          width: 100%;
        }
      }

      &__client {
        padding: 1rem .5rem;
      }
    }

    .refund-content {
      height: 100%;
      overflow: auto;

      @include scrollbar(5px, $primary);
    }

    .refund-bottom {
      background: $gray1;

      &__summary {

        &-row {
          font-size: 1.6rem;
          font-weight: 400;
          color: $title;
          padding: 0 1rem;

          &:first-child {
            padding-top: 1rem;
          }

          &:last-child {
            padding-bottom: 1rem;
          }

          &:not(:last-child) {
            border-bottom: 1px dashed $gray2;
          }
        }
      }
    }

    &__item {
      cursor: pointer;
      margin: 0;
      border-bottom: 1px solid $gray1;
      max-width: 100%;
      animation: fadeIn ease .2s;
      -webkit-animation: fadeIn ease .2s;
      -moz-animation: fadeIn ease .2s;
      -o-animation: fadeIn ease .2s;
      -ms-animation: fadeIn ease .2s;

      &:hover {
        background: $gray1;
      }

      &-data {
        flex: 1 1 50%;
        padding: 0 5px 0 0;
      }

      &-total-price {
        flex: 1 1 25%;
        padding: 0 5px;
      }

      &-name {
        white-space: break-spaces;
      }

      &-name,
      &-total-price {
        color: $title;
        font-weight: 500;
        font-size: 1.3rem;
      }

      &-price,
      &-reference {
        color: $subtitle;
        font-weight: 500;
        font-size: 1.4rem;

        @include media-breakpoint-down('md') {
          font-size: 1.3rem;
        }
      }

      &-actions {
        width: 120px;

        button {
          width: 24px;
          height: 24px;
          padding: 0;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }

      &-quantity {
        border: .2rem solid $primary;
        font-weight: 700;
        margin: 0 .5rem;
        width: 30px;
        height: 30px;
        font-size: 1.4rem;
      }

      &-delete {
        width: 2.5rem;
        border-right: none;
        padding: 5px 0 !important;

        &:hover {
          background: $secondary !important;

          svg {
            fill: $white !important;
          }
        }
      }
    }


  }

  .search-item-refund {
    .search-bar {
      height: 3.8rem;
      width: 100%;

      &>button {
        height: 100%;
        padding: 0 1.5rem;
        border-radius: 0;

        @include media-breakpoint-down('sm') {
          padding: 0 .5rem;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }

      .search-bar-input {
        width: 100%;
        height: 100%;

        border: 1px solid $primary;
        border-left: none;
        color: $title;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0 4rem 0 1rem;
        outline: none;
        font-size: 1.5rem;

        &::-webkit-input-placeholder {
          /* WebKit browsers */
          text-transform: uppercase;
          color: $subtitle;
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          text-transform: uppercase;
          color: $subtitle;
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          text-transform: uppercase;
          color: $subtitle;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          text-transform: uppercase;
          color: $subtitle;
        }

        &::placeholder {
          /* Recent browsers */
          text-transform: uppercase;
          color: $subtitle;
        }
      }

      .search-bar-container {
        position: relative;

        button {
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }

    .refund-item-list {
      overflow: hidden;
      overflow-y: auto;
      @include scrollbar(5px, $gray2);

      .refund-item {
        border: 1px solid $gray1;
        border-radius: 5px;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 2px;
        }

        &:hover {
          background: $gray1;
        }
      }
    }
  }

  .refund-settings,
  .refund-edit-item {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $white;
    transform: translateX(100%);
    transition: all .2s ease;

    &.open {
      transform: translateX(0);
    }

    .Select__menu-portal {
      position: initial !important;
    }

    .item-summary {
      background: $gray1;
      font-size: 1.4rem;

      &__row:not(:last-child) {
        border-bottom: 1px $gray2 dashed;
      }
    }
  }

  .btn-refund-method {
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }

    padding: 1rem .25rem !important;
    height: 140px;
    width: 150px;
    margin-bottom: 1rem;

    p.h5 {
      font-size: 1.2rem !important;
    }

    &.active {
      border: 2px solid $primary !important;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

&.modal__refunds-new {
  border-radius: 16px;
  height: 550px;
  width: 100%;
  transition: height 0.3s ease-in-out;

  .container-body-error {
    overflow-y: scroll;
    @include scrollbar(5px, $gray2);
  }

  &>div {
    height: 100%;
  }

  .button-go-back-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: -5px;
    margin-right: 5px;
    font-weight: 500;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .modal__body {
    height: 100%;
  }

  &.step-1 {
    max-width: 600px;
    height: 400px;
  }

  &.step-2 {
    max-width: 600px;
    height: 540px;
  }

  @import "./newRefunds/step3";

  .warning-icon-refund {
    width: 16px;
    height: 16px;
  }

  .warning-refund-item-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #B45309;
  }

  &.step-4 {
    max-width: 700px;
    height: 500px;
  }

  &.step-4.method-combined {
    height: 585px;
  }

  .refund-section-title {
    font-size: 14px;
    font-weight: 500;
    color: #94A3B8;
  }

  .items-summary-container {
    border-left: 1px solid #94A3B833;
    height: 188px;
    overflow-y: scroll;
  }

  @include media-breakpoint-down('sm') {
    .items-summary-container {
      border-left: none;
      height: 140px;
    }
  }

  .items-summary-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: transparent;
  }

  .items-summary-container::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    outline: none;
    border-radius: 5px;
  }

  .item-summary {
    border-radius: 10px;
    border: 1px solid #94A3B833;
    padding: 8px 16px;
    margin-bottom: 10px;

    &__name {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #0F172A;
    }

    &__price {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #0F172A;
    }

    &__reference {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #64748B
    }

    &__quantity {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #64748B
    }
  }

  @include media-breakpoint-up('md') {
    max-width: 480px;
  }

  @include media-breakpoint-down('sm') {
    width: 320px;
    height: 600px;
    padding: 16px;

    .new-refund-input .text-warning {
      margin-top: 10px;
    }

    .container-methods {
      height: 400px;
      overflow-y: scroll;
      @include scrollbar(5px, $gray2);
    }

    .container-buttons-controls {
      width: 100%;
      justify-content: space-between !important;

      button {
        width: 48% !important;
      }

      .disabled-button-creditToSales {
        width: 100% !important;
      }
    }

    &.step-4 {
      max-width: 635px;
      height: 425px;
    }

    &.step-4.method-cash {
      height: 525px;
    }

    &.step-4.method-creditToSales {
      height: 650px;
    }

    &.step-1 {
      height: 500px;
    }

    &.step-2 {
      height: 717px;
    }

    &.step-3 {
      height: 700px;
      width: 350px;
    }

    &.step-4.method-combined {
      height: 694px;
    }
  }

  .date-refund .react-datepicker-wrapper {
    width: 100%;
  }

  .warning-text {
    width: 100% !important;
    text-align: left !important;
  }

  p.warning-text {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #0F172A !important;
  }

  a.warning-text {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: #30ABA9 !important;
  }

  .refund-combined-subtitle div {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #334155;
  }

  .refund-combined-subtitle-sm {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #334155;
    width: 100%;
    margin-bottom: 20px;
  }

  .refund-total h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: $new-text-primary;
  }

  .refund-document-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .refund-document-info div:first-child {
    color: #334155;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .refund-document-info div:last-child {
    color: #64748B;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  @include media-breakpoint-down('sm') {
    .refund-document-info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .refund-total span {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  .title-new-refund {
    color: $new-text-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  .refund-choose-method {
    .subtitle-refund-method {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .refunds-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #CBD5E1;
    color: $new-text-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: transparent;
  }

  .refunds-cancel-button:hover {
    background: #CBD5E154;
  }

  .tag-electronic {
    padding: 4px 8px;
    font-size: 11px;
    font-style: normal;
    border-radius: 6px;
    font-weight: 600;
    line-height: 16px;
    background-color: #E0E7FF;
    color: #4F46E5;
  }

  .btn-refound-method-new {
    border-radius: 16px;
    border: 2px solid $border-secondary-alt;
    padding: 1px;
  }

  .btn-refound-method-new:hover {
    border: 2px solid rgb(0, 177, 157);
  }

  .icon-refund-method {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid $border-secondary-alt;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .title-method-refund {
    color: $new-text-primary;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }

  .description-method-refund {
    color: $new-text-secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .refund-document-label {
    overflow-wrap: break-word;
  }

  .select-refund {

    label {
      color: $new-text-primary;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .refund-document-label {
      display: none;
    }

    .document-fullNumber {
      display: inline !important;
    }

    .refund-document-balance-label {
      display: none;
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
      visibility: hidden;
      max-height: 0;
    }

    90% {
      opacity: 0;
      visibility: hidden;
      max-height: 0;
    }

    100% {
      opacity: 1;
      visibility: visible;
      max-height: 100vh;
    }
  }

  .appear-element {
    animation: appear 0.35s;
  }

  .new-refund-input .Select__control {
    height: 40px;
    border-radius: 10px;
    border: 1px solid #CBD5E1;
  }

  .new-refund-input .input-number-wrapper {
    height: 40px;
    border-radius: 10px;
    border: 1px solid #CBD5E1;
  }

  .bottom {
    margin-top: auto;
  }

  .date-refund label {
    color: $new-text-primary;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .date-refund {
    padding-right: 4px !important;

    &.no-padding {
      padding-right: 0 !important;
    }
  }

  .date-refund input {
    max-width: 100%;
    padding: 2px 8px !important;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #CBD5E1 !important;
  }

  .divider {
    margin: 10px 0 15px 0;
    height: 1px;
    width: 100%;
    border-top: 1px solid #E2E8F0;
  }

  .help-link {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  .help-link a {
    font-size: 16px;
    color: #00b19d;
    text-decoration: none;
    background-color: transparent;
  }

  #refund-modal-portal {
    overflow-x: hidden;
  }

  .help-link a:hover {
    color: #006559;
    text-decoration: underline;
  }

  .form-field-item-description textarea {
    height: 50px !important;
  }

  .refund-edit-item {
    border-radius: 16px;
    overflow: hidden;

    .Select__menu-portal {
      position: initial !important;
    }

    .edit-product {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: #0f172a;
    }

    .name-edit-product {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: #0f172a;
    }
  }

  .refund-edit-item.open {
    display: flex !important;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .item-info {
    font-family: 'Inter';
    display: flex;
    font-size: 14px;

    &__image {
      width: 68px;
      height: 68px;
      border: 2px solid #E2E8F0;
      border-radius: 5px;

      img,
      svg {
        display: block;
        width: 100% !important;
      }
    }

    &__detail {
      p {
        margin: 0;
      }

      &-product-type {
        color: #0F172A;
      }

      &-product-name {
        font-family: 'Inter Semibold';
        color: #334155;
      }

      &-product-reference {
        color: #64748B;
      }
    }

    &__delete {
      margin-left: auto;
      display: flex;
      justify-content: center;
      cursor: pointer;
      width: 34px;
      height: 34px;
      border-radius: 50%;

      svg {
        margin: auto 0 !important;
      }
    }

    &__delete:hover {
      background-color: #F1F5F9;

      svg {
        path {
          fill: black;
        }
      }
    }

    &__delete:active {
      background-color: #E2E8F0;
    }

    div {
      overflow-y: hidden !important;
    }
  }

  .refund-quantity {
    width: 28px;
    height: 28px;
    border-radius: 5px;
    background-color: #f8fafd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #334155;
    border: 1px solid #979797;
  }

  @include media-breakpoint-down('sm') {
    .help-link a {
      width: 110px;
    }
  }
}