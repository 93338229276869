.ReactModal {

  &__Overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $slate-700-60 !important;
    overflow-y: auto;
    z-index: 100;
    padding: 8rem 1rem;

    @include scrollbar(5px, $gray3);

  }

  &__Content {
    width: auto;
    max-width: calc(100vw - 2rem);
    background: #fff;
    border-radius: 2rem;
    outline: none;
    position: relative !important;
    top: 50%;
    transform: translateY(-50%);

    .modal {
      &__bottom {
        &-buttons {
          button:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }

      &__header-modal-close-button-absolute {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }

    // @include media-breakpoint-up("md") {
    //   max-width: 45rem;
    //   width: 45rem;
    // }

    // @include media-breakpoint-down("sm") {
    //   width: 30rem;
    // }

    @import "modals/open-shift";
    @import "modals/close-shift";
    @import "modals/new-close-shift";
    @import "modals/payments";
    @import "modals/invoice";
    @import "modals/refunds";
    @import "modals/item";
    @import "modals/stations";
    @import "modals/logout";
    @import "modals/tutorial-wizard";
    @import "modals/products-onboarding";
    @import "modals/suspended-plan";
    @import "modals/plan-limit-reached";
    @import "modals/activate-fe";
    @import "modals/variant";
    @import "modals/custom-field";
    @import 'modals/new-terms-col';
    @import 'modals/daily-report';
    @import 'modals/inventory-adjustment';
    @import 'modals/activate-5uvt';
    @import 'modals/confirmation';
    @import 'modals/activate-wompi';
    @import 'modals/select-numerations';
    @import 'modals/payment-declined';
    @import 'modals/solutions';
    @import 'modals/happy-week-experiment';
    @import 'modals/change-ordinary-numeration';

    &.modal__mobile-bottom {
      @include media-breakpoint-down("sm") {
        bottom: 0 !important;
        top: auto !important;
        left: 0 !important;
        position: absolute !important;
        transform: none !important;
        width: 100vw !important;
        max-width: 100vw !important;

        &>div {
          width: 100vw;
        }
      }
    }

    &.modal__rounded {
      overflow: hidden !important;
      border-radius: 1rem !important;
    }

    &.modal__medium-size {
      @include media-breakpoint-up("md") {
        max-width: 68rem;
        width: 68rem;
      }

      @include media-breakpoint-down("sm") {
        width: 50rem;
      }
    }

    &.min-height-sm {
      min-height: 38rem;
    }
  }
}

.ReactModal__Overlay:has(.modal__mobile-bottom) {
  padding: 0 !important;
  height: 100vh;
}

@import "modals/station-setting";