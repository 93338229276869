.custom-date-picker-container {
    position: relative;

    &>div:nth-child(1) {
        position: absolute;
        bottom: 0;
        cursor: pointer;
        width: 100%;
        margin: 0;

        .ep-arrow {
            display: none;
        }

        &>div>div {
            width: 100%;
        }

        &>div>div>input {
            opacity: 0;
            cursor: pointer;
            width: 100%;
        }
    }
}

.custom-date-picker {
    border: none !important;
    box-shadow: 0px 2px 6px -2px #0000000D;
    box-shadow: 0px 10px 15px -3px #0000001A;

    @include media-breakpoint-down('sm') {
        max-width: 100%;

        .rmdp-top-class {
            overflow-x: auto;
        }

        .rmdp-action-buttons {
            display: none !important;
        }
    }
}

.custom-date-picker-actions {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    padding: 1.2rem;
    border: 1px solid $slate-200;
    border-width: 1px 0 0 0;
    cursor: default;
}

.rmdp-arrow-container:hover {
    background-color: transparent !important;
    box-shadow: none !important;
}

.rmdp-arrow {
    border: solid $slate-700 !important;
    border-width: 0 1px 1px 0 !important;
    height: 8px !important;
    width: 8px !important;
    cursor: pointer;
}

.rmdp-arrow-container.disabled {
    .rmdp-arrow {
        border: solid $slate-200 !important;
        border-width: 0 1px 1px 0 !important;
        cursor: default;
    }
}

.rmdp-header-values span:first-child,
.rmdp-header-values span:last-child {
    position: relative;
    padding-right: 14px;
    font-weight: 600;

    &::before {
        content: '';
        position: absolute;
        border: solid $slate-700 !important;
        border-width: 0 0 2px 2px !important;
        height: 8px !important;
        width: 8px !important;
        right: -3px;
        top: 3px;
        transform: rotate(-45deg);
    }
}

.rmdp-header-values span:nth-child(2) {
    padding: 0 0 0 10px;
}

.rmdp-day-picker {
    gap: 10px;

    .rmdp-day {
        width: 40px !important;
        height: 40px !important;
    }

    .rmdp-day span {
        color: $slate-900;
        border-radius: 12px !important;
        width: 40px !important;
        height: 40px !important;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;

        &:hover {
            color: $primary-600 !important;
            background-color: transparent !important;
            border: solid $primary-600 !important;
        }
    }

    .rmdp-week-day {
        color: $slate-500 !important;
        font-weight: 500;
        font-family: 'Inter Medium';
    }

    .rmdp-day.rmdp-range {
        background-color: $primary-50 !important;
        box-shadow: none !important;
    }

    .rmdp-deactive span,
    .rmdp-disabled span {
        color: $slate-300 !important;
        cursor: default !important;

        &:hover {
            color: $slate-300 !important;
            background-color: transparent !important;
            border: none !important;
        }
    }

    .rmdp-day.rmdp-range.start,
    .rmdp-day.rmdp-range.end {
        background-color: $primary-50 !important;

        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-color: $primary-600;
            width: 100%;
            height: 100%;
            border-radius: 12px !important;
            top: 0;
            left: 0;

        }

        span {
            color: $white;

            &:hover {
                color: $white !important;
            }
        }
    }


    .rmdp-day-picker>div {
        width: 280px;

        .rmdp-week {
            height: 40px;
        }

        .rmdp-week:first-child {
            height: 32px;
        }
    }
}

.rmdp-year-picker,
.rmdp-month-picker {
    min-width: 280px;

    .rmdp-day span {
        color: $slate-900;
        border-radius: 12px !important;
        height: 100% !important;

        &:hover {
            color: $primary-600 !important;
            background-color: transparent !important;
            border: solid $primary-600 !important;
        }
    }

    .rmdp-day.rmdp-selected {
        background-color: $primary-600;
        border-radius: 12px !important;

        span {
            color: $white;
            background-color: transparent !important;
            box-shadow: none !important;
            height: 100% !important;

            &:hover {
                color: $white !important;
            }
        }
    }

    .rmdp-deactive span,
    .rmdp-disabled span {
        color: $slate-300 !important;
        cursor: default !important;

        &:hover {
            color: $slate-300 !important;
            background-color: transparent !important;
            border: none !important;
        }
    }
}