.master-detail {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &__pagination {
    flex: 1 0 20%;
    background: $white;
    max-width: 35rem;
    
    @include media-breakpoint-down('xs') {
      flex: 1 1 100%;
      max-width: 100vw;
    }

    .pagination-header {
      box-shadow: 0px 0.5px 4px $gray3;
    }

    .body {
      max-height: 100%;
      overflow: hidden;
      overflow-y: auto;
      overflow-x: auto;

      @include scrollbar(7px, $gray3);
    }

    .pagination-empty {
      .card {
        border: none;
      }
    }

    .pagination-bottom {
      input.pagination-per-page {
        border: 1px solid $white;
        border-radius: 5px;
        background: $primary;
        color: $white;
        margin: 0;
        width: 4.2rem;
        outline: none;
        padding: 0 5px;
        height: 3rem;
        text-align: end;

        &:focus, &:active {
          border: 1px solid $white;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
        }
        -moz-appearance: textfield !important;
      }
    }
  }

  &__detail {
    flex: 2;
    background: $empty;
    padding: 4rem 4.5rem;
    transition: all .2s ease;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
    
    @include scrollbar(7px, $gray3);

    @include media-breakpoint-down('xs') {
      position: absolute;
      flex: none;
      width: 100%;
      height: 100%;
      transform: translateX(100vw);
    }

    &.item-selected {
      @include media-breakpoint-down('xs') {
        transform: translateX(0);
      }
    }

    .detail-header {
      .title {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $grayblue7;
      }
    }

  }

  @import 'masterDetail/invoices';
  @import 'masterDetail/payments';
  @import 'masterDetail/refunds';
}