.form-body,
.company-form-body {
  overflow-y: auto !important;
  @include scrollbar(5px, $gray2);

  .form-group {
    .label-wrapper {
      height: 25px;
      color: $subtitle;
    }

    label {
      font-size: 1.2rem;
      margin-bottom: 0;
      font-weight: 400;

      &.required {
        &::after {
          content: " *";
          color: $primary;
        }
      }
    }

    .input-number-wrapper {
      border: 1px solid #cbd5e1;
      border-radius: 5px;
    }

    .input-number-wrapper:focus-within {
      box-shadow: none;
      border: 1px solid $primary;
      outline: none;
    }

    input:focus,
    textarea:focus {
      box-shadow: none;
      border: 1px solid $primary;
      outline: none;
    }

    textarea {
      resize: none;
      height: 10rem;

      @include scrollbar(5px, $gray2);
    }

    .small-textarea {
      height: 7rem;
    }

    .field-with-action {
      button {
        position: absolute;
        right: 0.3rem;
        top: calc((3.5rem - 2.8rem) / 2);
        padding: 0 1rem;
        font-size: 1rem;
        height: 2.8rem;
        text-transform: capitalize;
        border: 0px;
        padding: 0 2px;

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
}

.company-form-body {
  overflow: inherit !important;
}

.form-group.has-error {

  div,
  input,
  textarea {
    border-color: $danger !important;
  }
}

.react-datepicker {
  font-family: inherit !important;
  font-size: 1.2rem;

  &-popper {
    z-index: 5;
  }

  &__header {
    font-size: 1.25rem;
  }

  &__input-container {
    width: 100%;

    input {
      height: 3.6rem;
      border: 1px solid $gray2 !important;
      outline-color: $primary;
      font-weight: normal;
    }
  }
}

.list-checkbox {

  /* The container */
  .container-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #707070;

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 3px;
      right: 10px;
      height: 16px;
      width: 16px;
      border: 1px solid #cccccc;
      border-radius: 4px;
    }

    /* On mouse-over, add a white background color */
    &:hover input~.checkmark {
      background-color: #fff;
    }

    /* When the checkbox is checked, add a primary background */
    input:checked~.checkmark {
      background-color: #00b19d;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked~.checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      right: 4.5px;
      top: 2px;
      width: 5px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.container-filter-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 40px;
  height: auto;
  min-width: 20em;
  background: $white;
  border-radius: 12px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 2px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 2;

  @include media-breakpoint-down("sm") {
    min-width: auto;
  }

  button {
    max-height: 32px;
  }
}

.container-icon-search {
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-activities {
  &__selected {
    background-color: $white;
    border: 1px solid #ccc;
    border-bottom: 0px;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    padding: 8px;
    overflow: auto;
    max-height: 120px;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background: #00b19d;
      border-radius: 5px;

      &:active {
        background: #00b19d;
      }
    }

    &__container {
      margin: 4px;
      background-color: #00b19d;
      color: $white;
      border-radius: 5px;
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
        margin: 0 5px;
        cursor: pointer;

        path {
          stroke: $white;
          stroke-width: 2px;
        }

        &.activity-favorite {
          fill: $white;
        }
      }
    }
  }

  &__list {
    position: absolute;
    border: 1px solid #ccc;
    border-top: 0px;
    background-color: $white;
    z-index: 1;
    right: 0;
    left: 0;
    max-height: 197px;
    overflow: auto;
    font-size: 12px;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background: #00b19d;
      border-radius: 5px;

      &:active {
        background: #00b19d;
      }
    }

    label {
      font-weight: 400;
      font-size: 12px !important;
      color: #474747;
    }
  }
}

.text-search-field-input {
  border: 1px solid $slate-300;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  height: 3.2rem;

  &-icon {
    position: absolute;
    left: 6px;
  }

  &-loading {
    position: absolute;
    right: 6px;
  }

  input {
    border: none;
    border-radius: 8px;
    padding-left: 28px;
    padding-right: 28px;
    height: 100%
  }
}

@import "forms/invoice";
@import "forms/item";
@import "forms/filter";