// Accent colors
$primary: #00b19d;
$primary2: #187577;
$primary3: #dcfce7;
$primary4: #15803d;
$secondary: #809bb6;
$success: #bfcd31;
$danger: #e85e42;
$danger2: #e11d48;
$warning: #f9d863;
$info: #4a90e2;

// Color for hover and those things
$primary-dark: #008e79;
$success-dark: #848d26;
$danger-dark: #bf5037;

$primary-light: #bde3dc;

// Font colors
$light: #fff;
$dark: #58595f;
$blue-dark: #00535e;
$title: #474747;
$subtitle: #707070;

// Other colors
$border-color: #e8e8e8;
$border-secondary-alt: #e2e8f0;

// Empty space
// $empty: #e8ebf0;
$empty: #f4f5fb;

// White background
$white: #fff;

// Gray
$gray1: #f2f2f2;
$gray2: #cccccc;
$gray3: #b3b3b3;
$gray4: #999999;
$gray5: #808080;
$gray6: #595959;
$grayblue: #f4f5fb;
$grayblue6: #8592ad;
$grayblue7: #47536b;

// Shadow background
$shadow-background: rgba($dark, 0.75);

// New Active Invoice variables
$new-text-primary: #0f172a;
$new-text-secondary: #334155;
$new-text-tertiary: #64748b;

// Notification colors
$success-primary: #aad572;
$success-secondary: #fafff5;
$warning-primary: #ffd772;
$warning-secondary: #fefaea;
$danger-primary: #ff8b73;
$danger-secondary: #fff2f3;
$info-primary: #4a90e2;
$info-secondary: #eefcff;

// Theme colors
$theme-colors: (
  "primary": $primary,
  "primary-light": $primary-light,
  "secondary": $secondary,
  "dark": $dark,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "primary-dark": $primary-dark,
);

// Spacing
$headerHeight: 5rem;
$footerHeight: 4.4rem;

$btn-font-size: 1.6rem;

$input-height: 3.6rem;
$input-font-size: 1.4rem;
$input-color: $subtitle;
$input-border-color: $gray2;
$input-border-radius: 5px;

$border-radius: 5px;
$border-radius-lg: 0.4rem;

$dropdown-font-size: 1.6rem;
$font-family-sans-serif: Inter;

.break-all {
  word-break: break-all !important;
  word-wrap: break-word !important;
}

.pointer {
  cursor: pointer !important;
}

.noselect {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.underline-hover:hover {
  text-decoration: underline;
  color: #006559 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

.text-capitalize-first::first-letter {
  text-transform: uppercase !important;
}

.icon-primary {
  fill: $primary !important;
}

.icon-primary2 {
  fill: $primary2 !important;
}

.icon-secondary {
  fill: $secondary !important;
}

.icon-empty {
  fill: $empty !important;
}

.icon-white {
  fill: $white !important;

  path {
    fill: $white !important;
  }
}

button[type="button"] .icon-white path {
  fill: $white !important;
}

.icon-secondary path {
  fill: $new-text-secondary !important;
}

.icon-gray {
  fill: $gray3 !important;
}

.icon-danger {
  fill: $danger !important;
}

.icon-danger2 {
  fill: $danger2 !important;
}

.icon-warning {
  fill: $warning !important;
}

.icon-warning path {
  fill: $warning !important;
}

.icon-black {
  fill: black !important;
}

.icon-title {
  fill: $title !important;
}

.icon-grayblue7 {
  fill: $grayblue7 !important;
}

.text-title {
  color: $title !important;
}

.text-title-secundary {
  color: #0f172a !important;
}

.text-subtitle {
  color: $subtitle !important;
}

.text-subtitle-muted {
  color: $grayblue6 !important;
}

.text-secondary {
  color: $new-text-secondary !important;
}

.icon {
  &.x1-5 {
    width: calc(1.5 * 24px) !important;
    height: calc(1.5 * 24px) !important;
  }

  &.x2 {
    width: calc(2 * 24px) !important;
    height: calc(2 * 24px) !important;
  }

  &.x3 {
    width: calc(3 * 24px) !important;
    height: calc(3 * 24px) !important;
  }

  &.x4 {
    width: calc(4 * 24px) !important;
    height: calc(4 * 24px) !important;
  }

  &.icon-label {
    margin-left: 0.5rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.rotate-animation {
  animation: rotate 1s infinite linear;

  @keyframes rotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.icon-stroke-primary {
  stroke: $primary !important;
}

.icon-stroke-secondary {
  stroke: $secondary !important;
}

.icon-stroke-empty {
  stroke: $empty !important;
}

.icon-stroke-white {
  stroke: $white !important;
}

.icon-stroke-gray {
  stroke: $gray3 !important;
}

.svg-icon-primary {
  width: 12px;
  height: 12px;
  margin: 0 5px;

  path {
    stroke: $white;
    stroke-width: 2px;
  }

  fill: $primary;
}

.svg-icon-warning-delete {
  width: 12px;
  height: 12px;
}

.rounded2 {
  border-radius: 0.8rem !important;
}

.svg-icon-sucess-background {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #dcfce7;
  border-radius: 32px;
}

.svg-icon-inactive-background {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #e2e8f073;
  border-radius: 32px;
}

.btn-submit {
  margin: 0;
  height: 3.8rem;
  min-width: 10rem;
  font-weight: 500;
  font-size: 1.3rem !important;
  border-radius: 5px;
  color: $white !important;
  background: $primary !important;

  &:hover {
    &:not(:disabled) {
      background: $primary-dark !important;
    }
  }

  &:active {
    &:not(:disabled) {
      background: $primary-dark !important;
    }
  }

  &::first-letter {
    text-transform: uppercase !important;
  }
}

.btn-danger {
  margin: 0;
  height: 3.8rem;
  min-width: 10rem;
  font-weight: 500;
  font-size: 1.3rem !important;
  border-radius: 5px;
  color: $white !important;
  background: #e11d48 !important;

  &:hover {
    &:not(:disabled) {
      background: #be123c !important;
    }
  }

  &:active {
    &:not(:disabled) {
      background: #9f1239 !important;
    }
  }

  &::first-letter {
    text-transform: uppercase !important;
  }

  &:disabled {
    background: #e11d48 !important;
    opacity: 0.3;
  }
}

.btn-submit-large {
  margin: 0;
  height: 3.8rem;
  min-width: 20rem;
  font-weight: 500;
  font-size: 1.4rem !important;
  border-radius: 5px;
  color: $white !important;
  background: $primary !important;

  &:hover {
    &:not(:disabled) {
      background: $primary-dark !important;
    }
  }

  &:active {
    &:not(:disabled) {
      background: $primary-dark !important;
    }
  }

  &::first-letter {
    text-transform: uppercase !important;
  }
}

.btn-secondary {
  margin: 0;
  height: 3.8rem;
  min-width: 10rem;
  font-weight: 500;
  font-size: 1.3rem !important;
  border-radius: 5px;
  color: $primary !important;
  border-color: $primary !important;
  background: $white !important;

  &::first-letter {
    text-transform: uppercase !important;
  }
}

.btn-action {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 0.4rem;
  min-height: 3.2rem;
  border: 1px solid #cbd5e1 !important;
  border-radius: 8px;
  background: $white !important;

  font-size: 1.4rem;
  line-height: 2rem;

  text-align: center;

  color: #0f172a;

  &:hover {
    &:not(:disabled) {
      border: 1px solid #cbd5e1 !important;
      background: rgba(226, 232, 240, 0.45) !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 1px solid #cbd5e1 !important;
      background: rgba(226, 232, 240, 0.7) !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    border: 1px solid #cbd5e1 !important;
  }

  &::first-letter {
    text-transform: uppercase !important;
  }

  @media (max-width: 576px) {
    height: 4rem;
  }
}

.btn-action__solid {
  height: 2.5rem;
  min-width: 9rem;
  font-weight: 500;
  font-size: 1.3rem !important;
  border-radius: 5px;
  color: $white !important;
  border: 1px solid $gray3 !important;
  background: $primary !important;

  &:hover {
    &:not(:disabled) {
      border: 1px solid $primary !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 1px solid $primary !important;
    }
  }

  &::first-letter {
    text-transform: uppercase !important;
  }

  @media (max-width: 576px) {
    height: 4rem;
  }
}

.btn-cancel,
.btn-activate {
  margin: 0;
  height: 3.8rem;
  min-width: 10rem;
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  border-radius: 5px;
  color: $blue-dark !important;
  background: $white !important;
  border: 1px solid $gray3 !important;

  &:hover {
    &:not(:disabled) {
      border: 1px solid $primary !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: 1px solid $primary !important;
    }
  }

  &::first-letter {
    text-transform: uppercase !important;
  }
}

.btn-activate {
  height: 3rem;
  min-width: 7rem;
  color: $gray6 !important;
  border: 1px solid $gray2 !important;
}

.btn-no-border {
  border: none !important;
  color: $primary2 !important;

  &:hover {
    &:not(:disabled) {
      border: none !important;
    }
  }

  &:active {
    &:not(:disabled) {
      border: none !important;
    }
  }
}

.btn-transparent-inline {
  margin: 0;
  font-size: 1.3rem !important;
  border-radius: 5px;
  background: transparent !important;
  border: transparent !important;

  &:hover {
    &:not(:disabled) {
      color: $blue-dark !important;
    }
  }

  &::first-letter {
    text-transform: uppercase !important;
  }
}

.btn-generic {
  margin: 0;
  height: 3.8rem;
  min-width: 10rem;
  font-weight: 500;
  font-size: 1.25rem !important;
}

.btn-round {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background: $white;
  border: 1px solid #e2e8f0;
  border-radius: 37px;
}

.btn-new-product {
  margin: 0;
  height: 3.8rem;
  min-width: 20rem;
  font-weight: 600 !important;
  font-size: 1.6rem !important;
  border-radius: 5px;
  color: $primary !important;
  background: $white !important;
  border: 1px solid $primary !important;
}

.btn-select-filter {
  height: 40px;
  width: 124px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: $white;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
}

.btn-small {
  font-size: 1.2rem !important;
}

.btn-text-dark {
  color: $dark !important;
}

.text-btn-normal {
  font-weight: normal !important;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

input[type="radio"] {
  cursor: pointer;
  visibility: hidden;
  width: 15px;
  height: 15px;
  margin-bottom: 2px;

  &:hover {
    &:after {
      border: 1px solid $primary;
    }
  }

  &:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    position: relative;
    background-color: transparent;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid $gray3;
    transition: all 0.2s ease;
  }

  &:checked:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    position: relative;
    background-color: $primary;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid $gray3;
  }
}

input[type="checkbox"] {
  cursor: pointer;
  visibility: hidden;
  width: 18px;
  height: 18px;
  margin-bottom: 2px;

  &:hover {
    &:after {
      border: 1px solid $primary;
    }
  }

  &:after {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    position: relative;
    background-color: transparent;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid $gray3;
    transition: all 0.2s ease;
  }

  &:checked:after {
    background-color: $primary;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    position: relative;
    background-color: $primary;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 10l4 4 8-8'/></svg>");
    content: "";
    display: inline-block;
    visibility: visible;
    border: none;
  }

  &.hyphen {
    &:checked:after {
      background-color: $primary;
      width: 15px;
      height: 15px;
      border-radius: 4px;
      position: relative;
      background-color: $primary;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 10h10'/></svg>");

      // background-color: red;
      // background-image: url("data:image/svg+xml, <svg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M0.479126 1C0.479126 0.758375 0.675001 0.5625 0.916626 0.5625H9.08329C9.32492 0.5625 9.52079 0.758375 9.52079 1C9.52079 1.24162 9.32492 1.4375 9.08329 1.4375H0.916626C0.675001 1.4375 0.479126 1.24162 0.479126 1Z' fill='white'/></svg>");
      content: "";
      display: inline-block;
      visibility: visible;
      border: none;
    }
  }
}