.header {
  flex: 0 0 $headerHeight;
  background: $primary;

  &__profile-options {
    width: 28.3rem !important;

    .menu-option {
      font-size: 1.4rem !important;
    }

    >div:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &__station-options {
    width: 30rem !important;

    &-header {
      padding: 1.2rem;
      color: $blue-dark !important;
      font-weight: 700;
      box-shadow: 0 1px 2px $gray1;
    }

    .station-row {
      font-size: 1.5rem;

      div:first-child {
        color: $subtitle;
      }

      div:last-child {
        text-align: right;
        color: $blue-dark;
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid $gray1;
    }
  }

  &__apps {
    width: 38rem !important;
    // padding: .5rem 1rem;

    &-header {
      padding: 0.3rem;
      font-size: 1.4rem;
      width: 100%;
      border-radius: 16px 16px 0 0;
      background-color: #f8f9ff;
      color: $gray6 !important;
      font-weight: 500;

      &.modern {
        background-color: $white;
        color: $new-text-tertiary !important;
      }
    }

    &-footer {
      padding: 0.3rem;
      font-size: 1.4rem;
      width: 100%;
      border-radius: 0 0 16px 16px;
      background-color: rgba(248, 250, 252, 1);
      color: #30aba9 !important;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      cursor: pointer;

      svg {
        margin-bottom: 3px;

        path {
          fill: #30aba9 !important;
        }
      }

      &:hover {
        background-color: rgba(226, 232, 240, 0.45);
      }

      &:active {
        background-color: rgba(226, 232, 240, 0.7);
      }
    }

    .app_content {
      border-bottom: 1px solid $gray1;
    }

    .app_title {
      font-size: 1.4rem;
      color: $blue-dark !important;
      font-weight: 700;

      &.modern {
        color: $new-text-secondary !important;
      }

      &_description {
        color: $gray4;

        &.modern {
          color: $new-text-tertiary !important;
        }
      }
    }

    .form-row>div {
      padding: 1rem;
      border-radius: 8px;
    }

    .item-nav {
      margin: 0 8px;

      &:hover {
        background-color: rgba(226, 232, 240, 0.45);
        border-radius: 10px;
      }

      &:active {
        background-color: rgba(226, 232, 240, 0.7);
      }
    }
  }

  &__profile-btn {
    padding: 0 1.5rem;
    height: 4rem;

    @include media-breakpoint-down('xs') {
      padding: 0;
      background: transparent !important;
    }

    &-logo {
      color: $subtitle;
      font-size: 1.4rem;
      font-weight: 500;
      height: 3.6rem;
      vertical-align: middle;
      max-height: 3.6rem;
      max-width: 12rem;
      margin-right: 1rem;

      svg,
      img {
        height: 3.6rem;
        width: 12rem;
        vertical-align: middle;
        max-height: 3.6rem;
        max-width: 12rem;
        object-fit: cover;
        margin-right: 2px;
      }
    }

    &-initial {
      width: 32px;
      height: 32px;
      background: #4a90e2;
      vertical-align: middle;

      @include media-breakpoint-down('xs') {
        border: 2px $white solid;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    height: 100%;

    li {
      list-style: none;
    }
  }

  .btn-menu {
    border-radius: 5px;

    &:hover {
      background: $primary-dark;
    }
  }
}

.custom-menu-options-large {
  width: 30rem !important;
}

.custom-header {
  @extend .header;

  flex: 0 0 5.6rem;
  max-height: 5.6rem;
  background: $white;
  border-bottom: 1px solid #E2E8F0;

  .profile-btn-new-initial {
    width: 2.4rem;
    height: 2.4rem;
    background: $indigo-200;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

.menu {
  position: relative;

  @include media-breakpoint-down('xs') {
    position: initial;
  }

  &:hover {
    .menu-options {
      opacity: 1;
      visibility: visible;
    }
  }

  .menu-options {
    position: absolute;
    right: 0;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
    z-index: 3;
    top: calc(#{$headerHeight} + 20px);
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;

    &.header__apps {
      border-radius: 16px;
    }

    &.show {
      top: $headerHeight;
      opacity: 1;
      visibility: visible;
    }

    .menu-option {
      display: block;
      min-width: 15rem;
      white-space: nowrap;
      color: $subtitle;
      font-size: 1.4rem;
      text-decoration: none;
      width: 100%;

      &::first-letter {
        text-transform: capitalize;
      }

      div,
      p,
      a {
        display: block;
        padding: 1rem 2rem;
        color: inherit !important;
        text-decoration: none;
      }

      &.suboption {
        border-bottom: none !important;

        div,
        p,
        a {
          padding: 1rem 3rem;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray1;
      }

      &:hover {
        cursor: pointer;
        color: $primary;
      }

      .btn-link-notification {
        position: absolute;
        left: 7px;
        top: calc(0.65rem + 10px);
        width: 0.7rem;
        height: 0.7rem;
        background: $danger;
        border-radius: 50%;
        padding: 0;
      }

      .icon-left-menu {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      .icon-right-menu {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    &-header {
      padding: 0.8rem;
      padding-left: 1.9rem;
    }

    &-footer {
      width: 100%;
      border-radius: 0 0 16px 16px;
      background-color: rgba(248, 250, 252, 1);
      color: #30aba9 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 4.4rem;
      margin-top: 1.2rem;
      gap: 0.4rem;


      svg {
        path {
          fill: #30aba9 !important;
        }
      }

      &:hover {
        background-color: rgba(226, 232, 240, 0.45);
      }

      &:active {
        background-color: rgba(226, 232, 240, 0.7);
      }
    }
  }
}

.new-menu {
  @extend .menu;
  position: relative;

  &-item {
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    width: 4rem;
    z-index: 9;
  }

  &-container-small {
    width: 32rem !important;

    .body-apps {
      gap: 0.6rem;
    }

    .body-apps>div {
      border-radius: 1rem;
      height: 5rem;
      transition: .6s all;
    }

    .body-apps>div:hover {
      background-color: $slate-200-45;

    }
  }

  ul {
    padding: 0 !important;

    li {
      padding: 0 0.8rem;
    }

    .menu-submenu-big {
      border-bottom: 1px solid $slate-200;
      padding: 0.6rem 0.8rem;
    }

    li:last-child {
      border-top: 1px solid $slate-200;
      padding: 0.6rem 0.8rem;
    }
  }

  @include media-breakpoint-down('sm') {
    .mobile-right {
      right: -10rem;
    }
  }

  .menu-options {
    box-shadow: 0px 2px 6px -2px #0000000D;
    box-shadow: 0px 10px 15px -3px #0000001A;
    min-width: 32rem;
    border: 1.6rem;
    top: 5.6rem;
    opacity: 1;
    visibility: visible;
    border-radius: 1.6rem;

    &-overlay {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &-menu-header {
      padding-left: 1.9rem !important;
    }

    &-item:nth-child(1) {
      border-bottom: 1px solid #E2E8F0;
    }

    &-item {
      max-height: 7.9rem;
      min-height: 7.9rem;
      padding: 0 1.6rem;
      align-items: center;
    }

    .menu-option {
      transition: .6s all;

      p {
        padding: 0;
        color: $slate-700 !important;
      }

      &:hover {
        background-color: $slate-100;

        svg path {
          fill: $primary;
        }
      }
    }
  }

  &-container-button {
    border: 1px solid #E2E8F0;
    border-radius: 0.9rem !important;
    height: 4rem;
    z-index: 9;

    &:focus {
      box-shadow: none !important;
    }

    @include media-breakpoint-down('sm') {
      border: 0;
    }
  }
}

.view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down('md') {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;

    &-actions {
      width: 100%;

      div {
        button {
          background-color: red;
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-down('xs') {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1rem;
  }

  &-subtitle,
  &-info,
  &-error {
    font-size: 16px;
  }
}