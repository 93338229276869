$buttonNavigationWidth: 4.4rem;

.footer {
  flex: 1 1 $footerHeight;
  background: $gray1;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.2);

  &__wrapper {
    padding-right: $buttonNavigationWidth;
    
    &.footer-navigation {
      padding-left: $buttonNavigationWidth;
      padding-right: 3 * $buttonNavigationWidth;
    }
  }

  &__invoice-list {
    overflow-x: scroll;

    @include scrollbar(0, $primary);
  }

  &__invoice {
    min-width: 16rem;
    max-width: 16rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
    border-right: 1px solid rgba(0,0,0,.2);
    background: $gray1;
    cursor: pointer;
    animation: fadeIn ease .2s;
    -webkit-animation: fadeIn ease .2s;
    -moz-animation: fadeIn ease .2s;
    -o-animation: fadeIn ease .2s;
    -ms-animation: fadeIn ease .2s;


    &.active {
      border-top: 2px solid $primary;
      background: $white;
    }

    .invoice-icon {
      .invoice-pending-indicator {
        width: 1rem;
        height: 1rem;
        bottom: -0.5rem;
        right: -0.5rem;
        background: $danger;
        border-radius: 50%;
        border: 1px solid $white;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .invoice-name {
      font-size: 1.4rem;
      color: $title;
      font-weight: 500;
    }

    .invoice-options {
      border-radius: 5px;
      flex: 0 0 35px;
      width: 35px;
      height: 35px;
      box-shadow: none;
      border: none;

      svg {
        fill: $secondary !important;
      }

      &:hover:not(:disabled) {
        background: $empty;
      }
    }

  }

  &__invoice-options {
    top: 0;
    z-index: 2;
    background: $white;
    border-radius: 5px;
    width: 16rem;
  }

  &__quick-nav {
    max-height: #{"min(40rem, 70vh)"};
    top: 0;
    right: $buttonNavigationWidth;
    z-index: 2;
    background: $white;
    border-radius: 5px;
    width: 20rem;
    max-height: #{"min(40rem, 70vh)"};

    overflow-y: auto;

    @include scrollbar(5px, $gray3);

    &-option {
      border-right: none;

      &.active {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-left: 2px solid $primary;
        border-right: none;
        background-color: $white;
      }
    }
  }

  &__invoice-options, &__quick-nav {
    li {
      padding: 1rem;
      font-size: 14px;
      text-align: left;
      color: $subtitle;

      &:hover {
        background: $gray1;
      }
    }
  }

  &__navigation-left {
    left: 0;
    top: 0;
    bottom: 0;
    background: $white;
  }
  
  &__navigation-right {
    right: 0;
    top: 0;
    bottom: 0;
    background: $white;
    border-left: 1px solid rgba(0,0,0,.2);
  }

  button {
    background: $white;
    width: $buttonNavigationWidth;
    border-right: 1px solid rgba(0,0,0,.2);

    &:focus {
      box-shadow: none;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}