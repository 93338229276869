.Select__menu {
    &-list {
        .Select__group:nth-child(1) {
            border-top: none !important;
        }

        .Select__option {
            &--is-selected {
                div>svg>path {
                    fill: #30ABA9;
                }
            }
        }
    }
}