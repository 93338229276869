.items-grid {
  padding-top: 12px;
  overflow: hidden;
  overflow-y: auto;

  @include scrollbar(7px, $gray3);
  
  .item-view {
    flex: 0 0 22rem;
    padding: 1rem 1rem .5rem;
    cursor: pointer;
    max-width: 22rem;
    border: .2rem solid transparent;
    border-radius: 8px;
    margin: 0 2rem 2rem;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down('md') {
      flex: 0 0 18rem;
      max-width: 18rem;
      margin: 0 .5rem 2rem;
    }

    &.active {
      border: .2rem solid $primary;
    }

    &__favorite {
      right: 1rem;
      top: -2px;
      outline: none;
      box-shadow: none;
      height: 36px;
      width: 36px;
      z-index: 1;
      background: $primary;
      border-radius: 0 0 8px 8px !important;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);


      svg {
        height: 18px !important;
        path {
          stroke: $white;
          stroke-width: 2px;
        }
        
        &.item-favorite {
          fill: $white;
        }
      }
    }

    &__image-zone {
      flex: 0 0 16rem;
      width: 100%;
      border-radius: 8px;

      @include media-breakpoint-down('md') {
        flex: 0 0 14rem;
      }

      img {
        z-index: 1;
        object-fit: contain;
        -o-object-fit: contain;
      }
    }
    
    &__name {
      font-size: 14px;
      color: $title;
      margin: .7rem 0 .7rem 0;
    }
    
    &__price {
      font-size: 16px;
      color: $title;
      font-weight: 600;
    }

    &__reference {
      font-size: 12px;
      padding: .5rem;
      top: -2px;
      z-index: 1;
      background: #fafafa !important;
      border-radius: 0 0 8px 8px;
      max-width: 65%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }
    
    &__no-iva-tag {
      font-size: 12px;
      padding: .25rem 1rem;
      top: 7rem;
      left: -30px;
      z-index: 1;
      color: $white;
      transform: rotate(270deg);
      background: #F99D36 !important;
      border-radius: 0 0 8px 8px;
      max-width: 65%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }
    
    &__quantity {
      color: $primary;
      background: #fafafa !important;
      bottom: 0;
      left: 0; 
      right: 0; 
      margin-left: auto !important; 
      margin-right: auto !important; 
      font-size: 12px;
      padding: 0 .5rem;
      border-radius: 8px;
      width: fit-content;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    }

    &__count {
      background: $white;
      border: .2rem solid $primary;
      width: 36px;
      height: 36px;
      top: -12px;
      right: -12px;
      font-size: 1.6rem;
      font-weight: 700;
      z-index: 1;
    }

    &__empty-image {
      top: 0;
    }
  }
}