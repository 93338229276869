.settings {
  overflow: auto;
  @include scrollbar(5px, $primary);

  @import 'settings/print';
}

.settings-menu {

  &__option {
    flex: 0 0 22rem;
    min-height: 27rem;
    max-height: 27rem;
    background: $white;
    border-radius: 6px;
    border-bottom: 3px $primary solid;
    margin: 0 1rem 2rem;
    padding: 1rem;

    &-header {
      font-weight: 500;
      padding-bottom: .5rem;
      margin-bottom: .5rem;
      border-bottom: 1px solid $gray1;
    }

    &-description {
      font-size: 1.2rem;
      margin-bottom: .5rem;
      max-height: 6rem;
      min-height: 6rem;
    }

    &-options {
      font-size: 1.4rem;

      a:not(:last-child) {
        padding-bottom: .5rem;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
}

.table-actions {

  >* {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  button {
    transition: border .2s ease;
    padding-left: 1px;
    padding-right: 1px;
    border: 1px $gray2 solid;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover:not(:disabled) {
      border-color: $primary;

      &.button-remove {
        border-color: $danger;
      }
    }

    &:disabled {
      svg {
        fill: $gray3 !important;
      }
    }
  }
}

.settings-container {
  font-family: 'Inter';
  padding: 24px 64px;
  margin: 0 auto;
  margin-bottom: 200px;
  max-width: 1361px;

  .view-header {
    h1 {
      font-size: 24px;
      font-family: 'Inter Semibold';
    }
  }

  .settings-body,
  .general-settings-body {
    border-radius: 16px;
    padding: 24px 40px;

    &-title {
      font-family: 'Inter Medium';
      font-size: 16px;
      margin: 0;

      &-disabled {
        margin: 0;
        color: #b7bac0;
      }
    }

    &-subtitle {
      font-size: 14px;
      margin-bottom: 14px;
      color: #6c757d;

      &-disabled {
        margin-bottom: 14px;
        color: #dde0e5;
        font-size: 14px;
      }
    }
  }

  .general-settings-body {
    // padding: 24px 12px;
    // padding-top: 12px;}
  }
}