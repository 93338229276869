$menuWidth: 30rem;

.app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-select-station {
    width: 50rem;

    @include media-breakpoint-down('xs') {
      width: 95%;
    }
  }

  &-error {
    width: 60rem;

    img {
      width: 55%;
    }

    &-img-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
    }

    .btn {
      padding: 0;
    }

    li {
      margin-bottom: 1rem;
    }

    li::marker {
      color: $primary;
      font-size: 1.5em;
    }

    @include media-breakpoint-down('xs') {
      width: 95%;
    }
  }

  .navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $shadow-background;
    z-index: 101;

    &.closed {
      z-index: -1;
      transition: z-index .1s linear;
      transition-delay: .2s;

      .menu {
        transform: translateX(calc(-1*#{$menuWidth}));

        @include media-breakpoint-down('xs') {
          transform: translateX(calc(-1*100vw));
        }
      }
    }

    .menu {
      height: 100%;
      background: $white;
      width: $menuWidth;
      transition: transform .2s ease-in-out;

      @include media-breakpoint-down('xs') {
        width: 100vw;
      }
    }

    @import 'navigation';
  }

  .new-navigation {
    @extend .navigation;

    background: $slate-700-60;

    &.closed {
      z-index: -1;
      transition: z-index .1s linear;
      transition-delay: .2s;

      .new-navigation-menu {
        transform: translateX(calc(-1*#{$menuWidth}));

        @include media-breakpoint-down('xs') {
          transform: translateX(calc(-1*100vw));
        }
      }
    }

    &-menu {
      height: 100%;
      width: 34rem !important;
      background: $white;
      width: $menuWidth;
      transition: transform .2s ease-in-out;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down('xs') {
        width: 100vw;
      }

      &-disabled {
        background-color: $slate-100;
      }

      &-action {
        cursor: pointer;
        transition: .5s all;
        justify-content: space-between;

        &:hover {
          background-color: $slate-100;
        }
      }

      &-header:first-child {
        height: 56px;
      }

      &-header {
        padding: 0.4rem 1.6rem;
        height: 48px;
        width: 100%;
        border-bottom: 1px solid $slate-200;
        display: flex;
        align-items: center;
        gap: 0.8rem;

        svg {
          width: auto;
        }

        &-helper {
          height: 48px;
        }
      }

      .menu-footer {
        border-top: 1px solid $slate-200;
        display: flex;
        height: 8rem;
        padding: 1.6rem;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.medium-icon {
  width: 35px;
}

.small-icon {
  width: 18px;
}

.x-small-icon {
  width: 12px;
}

.text-small {
  font-size: 1.2rem;
}

.hidden {
  visibility: hidden;
}

.input-date-picker {
  position: inherit !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  border-bottom-color: transparent !important;
  top: 50% !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  border-bottom-color: transparent !important;
  top: 50% !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  top: auto !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-content {
  margin-top: -10px;
}

.vertical-separator {
  width: 1px;
  height: 24px;
  background-color: $slate-200;
}

.container-light {
  border: 1px solid $slate-200;
  background-color: $white;
  padding: 3.2rem;
  border-radius: 1.6rem;
}

.op-50 {
  opacity: .5;
}

.menu-select-item {
  position: absolute;
  box-shadow: 0px 2px 6px -2px #0000000D;
  box-shadow: 0px 10px 15px -3px #0000001A;
  background: $white;
  border-radius: 1.6rem;
  min-width: 18rem;
  top: 4rem;
  z-index: 10;

  &-title {
    width: 100%;
    text-align: start;
    padding: 0.6rem .8rem;
    border-radius: 0.8rem;
    padding-bottom: 0;
  }
}

.mobile-padding {
  @include media-breakpoint-down('sm') {
    padding: 0 2rem;
  }
}

.header-breadcrumb {
  display: flex;
  align-items: center;
  gap: .8rem;
}

.rc-tooltip-content {
  margin-top: -10px;
}

.custom-sidebar-logo {
  height: 40px;
}

.rc-tooltip-content {


  .rc-tooltip-arrow {
    border-bottom-color: $slate-800;
    top: -6px;
  }

  .rc-tooltip-inner {
    padding: 0.8rem 1.6rem;
    color: $white;
    background-color: $slate-800;
    min-height: 3.2rem;
    border-radius: 0.8rem;
    max-width: 30rem;
  }
}


.tooltip-white {
  .rc-tooltip-inner {
    padding: 8px 10px;
    color: #333333;
    text-align: left;
    text-decoration: none;
    background-color: white;
    border-radius: 3px;
    min-height: 34px;
    border: 1px solid white;
  }

}

.container-mobile {

  overflow-y: auto;
  height: 100%;
  @include scrollbar(5px, $gray2);

  @include media-breakpoint-down('sm') {
    padding: 0;
  }
}

.gap-3 {
  gap: 0.8rem;
}

.column-mobile {
  @include media-breakpoint-down('md') {
    flex-direction: column;
  }
}